import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { httpGetEnums } from "services/enums";
import { httpGetMySupplier } from "services/suppliers"
import { httpGetMyCustomer } from "services/customers"

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const { t } = useTranslation();
  const [enums, setEnums] = useState();
  const [supplier, setSupplier] = useState();
  const [customer, setCustomer] = useState();
  const [sidenavOpen, setSidenavOpen] = useState(true);
  
  // Mobile check BEGIN
  const [isMobile, setIsMobile] = useState(false)
  
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /android|iphone|ipad|ipod|opera mini|iemobile|mobile/i.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);
  // Mobile check END

  const getEnums = () => {
    httpGetEnums().then(response => {
      setEnums(response?.data);
    }, error => {
      console.log("getEnums error", error);
    })
  }

  const getMyCustomer = () => {
    httpGetMyCustomer().then(res => {
      setCustomer(res?.data)
    })
  }
  
  const getMySupplier = () => {
    httpGetMySupplier().then(res => {
      setSupplier(res?.data)
    })
  }

  // Orderer
  
  const getTabKeyById = (id) => {
    if (id) {
      if (id === 1) {
        return "draft"
      }
      else if (id === 2) {
        return "sent"
      }
      else if (id === 3) {
        return "inprogress"
      }
      else if (id === 4) {
        return "ondelivery"
      }
      else if (id === 5) {
        return "completed"
      }
    }
    return "";
  }
  
  const getTabIdByKey = (key) => {
    if (key) {
      if (key === "draft") {
        return 1
      }
      else if (key === "sent") {
        return 2
      }
      else if (key === "inprogress") {
        return 3
      }
      else if (key === "ondelivery") {
        return 4
      }
      else if (key === "completed") {
        return 5
      }
    }
    return 0
  }
  
  const getTabNameById = (id) => {
    if (id) {
      if (id === 1) {
        return t("Luonnokset")
      }
      else if (id === 2) {
        return t("Lähetetty")
      }
      else if (id === 3) {
        return t("Käsittelyssä")
      }
      else if (id === 4) {
        return t("Saapuneet / matkalla")
      }
      else if (id === 5) {
        return t("Valmiit")
      }
    }
    return "";
  }
  
  const getTabNameByKey = (key) => {
    if (key) {
      if (key === "draft") {
        return t("Luonnokset")
      }
      else if (key === "sent") {
        return t("Lähetetty")
      }
      else if (key === "inprogress") {
        return t("Käsittelyssä")
      }
      else if (key === "ondelivery") {
        return t("Saapuneet / matkalla")
      }
      else if (key === "completed") {
        return t("Valmiit")
      }
    }
    return "";
  }
  
  // Supplier
  
  const getTabKeyByIdSupplier = (id) => {
    if (id) {
      if (id === 2) {
        return "received"
      }
      else if (id === 3) {
        return "inprogress"
      }
      else if (id === 4) {
        return "ondelivery"
      }
      else if (id === 5) {
        return "completed"
      }
    }
    return "";
  }
  
  const getTabIdByKeySupplier = (key) => {
    if (key) {
      if (key === "received") {
        return 2
      }
      else if (key === "inprogress") {
        return 3
      }
      else if (key === "ondelivery") {
        return 4
      }
      else if (key === "completed") {
        return 5
      }
    }
    return 0
  }
  
  const getTabNameByIdSupplier = (id) => {
    if (id) {
      if (id === 2) {
        return t("Saapuneet")
      }
      else if (id === 3) {
        return t("Käsittelyssä")
      }
      else if (id === 4) {
        return t("Matkalla")
      }
      else if (id === 5) {
        return t("Valmiit")
      }
    }
    return "";
  }
  
  const getTabNameByKeySupplier = (key) => {
    if (key) {
      if (key === "received") {
        return t("Saapuneet")
      }
      else if (key === "inprogress") {
        return t("Käsittelyssä")
      }
      else if (key === "ondelivery") {
        return t("Matkalla")
      }
      else if (key === "completed") {
        return t("Valmiit")
      }
    }
    return "";
  }
  
  return (
      <AppContext.Provider
        value={{
          customer,
          enums,
          getEnums,
          getMyCustomer,
          getMySupplier,
          getTabKeyById,
          getTabIdByKey,
          getTabNameById,
          getTabNameByKey,
          getTabKeyByIdSupplier,
          getTabIdByKeySupplier,
          getTabNameByIdSupplier,
          getTabNameByKeySupplier,
          isMobile,
          setSidenavOpen,
          sidenavOpen,
          supplier,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  
};

AppContext.defaultProps = {};
AppContext.propTypes = {
  children: PropTypes.node.isRequired
};

export const useAppContext = () =>
  useContext(AppContext);
