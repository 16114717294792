import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import {
  Card,
  Typography,
  Button,
  Link,
  IconButton,
  Breadcrumb,
  BreadcrumbItem,
} from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetCustomers } from "services/customers"
// import history from "services/history"

const CustomersList = ({history}) => {
  
  const { t } = useTranslation();
  
  // Table
  const [loading, setLoading] = useState(false)
  /*
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
    */
  const [rows, setRows] = useState([])
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "name",
  })
  
  const getCustomers = () => {
    setLoading(true)
    httpGetCustomers(filters).then(res => {
      setRows(res?.data)
      setLoading(false)
    })
  }
  
  /*
  useEffect(() => {
    getCustomers();
  }, [])
  */
  
  useEffect(() => {
    getCustomers();
  }, [filters])
  
  const headers = [
    { label: t("Asiakas-ID"), key: "id"},
    { label: t("Toimittaja-ID"), key: "supplier_id"},
    { label: t("Toiminnot"), key: "actions"},
    { label: t("Organisaatio"), key: "company"},
    { label: t("Kuluttaja"), key: "name"},
    { label: t("Koodi"), key: "code"},
    { label: t("Maa"), key: "country"},
    { label: t("Kaupunki"), key: "city"},
    { label: t("Käyttäjät"), key: "users_count"},
    { label: t("ERP-käyttäjä-ID"), key: "erp_user_id"},
    { label: t("MCF-käyttäjä-ID"), key: "id_mcf"}, // is this id_mcf ???
  ];
  
  const handleNew = () => {
    history.push(`/dashboard/customers/new`);
  }
  
  const handleEdit = (id) => {
    history.push(`/dashboard/customers/edit/${id}/basic`);
  }
  
  const handleUsers = (id) => {
    history.push(`/dashboard/customers/users/list/${id}/`);
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    // rows.results.map
    console.log("rows", rows)
    return rows && rows.map(row => {
      return {
        ...row,
        id: row?.id,
        name: (
          <>
            <Typography>{row?.first_name} {row?.last_name}</Typography>
          </>
        ),
        code: (
          <>
            <Typography>{row?.code}</Typography>
          </>
        ),
        city: (
          <>
            <Typography>{row?.city}</Typography>
          </>
        ),
        users_count: (
          <>
            <Typography>{row?.users_count}</Typography>
          </>
        ),
        actions: (
          <>
            <IconButton size="lg" onClick={() => handleEdit(row?.id)} iconName="pen" />
            <IconButton size="lg" onClick={() => handleUsers(row?.id)} iconName="users" badgeColor="warning" badgeValue={row?.users_count === 0 ? "!" : null}  />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="trash" />
          </>
        ),
      }
    })
  }, [rows])
  
  return (
    <>
      <Row>
        <Col>
          <PageHeader title={t("Asiakkaat")}></PageHeader>
        </Col>
        {/*
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
              <div className="p-2"><Typography variant="small"></Typography></div>
          </div>
        </Col>
        */}
      </Row>
      
      
      
      <Row className="d-flex justify-content-between mb-3">
        <Col>
          
        </Col>
        <Col className="text-right">
        {/* <Button color="success" onClick={handleNew}>{t("Lisää asiakas")}</Button> */}
        </Col>
      </Row>
      
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
        tableStickyHeader={true}
      />
      
    </>
  );
}

export default CustomersList;
