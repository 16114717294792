import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'
import { generatePath } from "react-router";

import { Row, Col } from "stories/layout";
import { 
  AlertModal,
  Breadcrumb,
  BreadcrumbItem, 
  Button, 
  Link, 
  Modal,
  Typography, 
} from "stories/components";
import { useAuthContext } from "contexts/AuthContext"
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { Input, SelectMulti } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import {
  httpGetAllMcfCampaigns
} from "services/campaigns"

const SalesPriceListEditProducts = ({history, location, match, id, campaignErpId, mcfCampaign, setMcfCampaign}) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext()
  const { notify } = useNotification();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (mcfCampaign?.prices) {
      setRows(mcfCampaign?.prices)
      setLoading(false);
    }
  }, [mcfCampaign])

  const [headers, setHeaders] = useState([
    // { label: t("sales_price_lists_header_erp_id","ERP-myyntihinnasto-ID"), key: "id", sortable:true, visible: true},
    { label: t("price_list_order_image","Kuva"), key: "image_url_mcf", sortable:false, visible: true},
    { label: t("price_list_order_customer_campaign_fk","Myyntihinnasto-ID"), key: "campaign_fk", sortable:true, visible: true},
    { label: t("price_list_order_preorder_id","Ennakkotilaustuote-ID"), key: "preorder_id", sortable:false, visible: true},
    { label: t("price_list_order_erp_id","ERP-ID"), key: "id", sortable:false, visible: true},
    { label: t("price_list_order_product_link","Tuotekortti"), key: "url_mcf", sortable:false, visible: true},
    { label: t("price_list_order_product_name","Nimi"), key: "name", sortable:false, visible: true},
    { label: t("price_list_order_product_code","Tuotekoodi"), key: "product_code", sortable:false, visible: true},
    { label: t("price_list_order_barcode","Viivakoodi"), key: "barcode", sortable:false, visible: true},
    { label: t("price_list_order_price","Myyntihinta"), key: "selling_price", sortable:false, visible: true},
    { label: t("price_list_order_quantity","Määrä"), key: "quantity", sortable:false, visible: true},
    { label: t("price_list_order_order_limit","Tilausrajoitus"), key: "order_limit", sortable:false, visible: true},
    { label: t("price_list_order_discount_price","Alennushinta (sis. ALV)"), key: "price", sortable:false, visible: true},
    { label: t("price_list_order_discount_percent","Alennus-%"), key: "sale_percentage", sortable:false, visible: true},
    { label: t("price_list_order_moq","MOQ"), key: "minimum_order_quantity", sortable:false, visible: true},
  ]);

  const getTableRows = useCallback(() => {
    let results = [];
    if (rows?.results) {
      results = rows?.results;
    }
    else if (rows) {
      results = rows;
    }

    if (results == null || results.length === 0) {
      return [];
    }
    
    return results.map(row => {
      
      /*
      const getOrderProductFieldValue = (fieldName) => {
        return getOrderProductValue(fieldName, productId, productVariationId);
      }
      */
      
      const hasVariations = false;
      
      // Tilattu määrä (tuotekoodilla)
      let orderProductAmount = row?.order_amount_value ? row?.order_amount_value : 0;
      
      return {
        ...row,
        url_mcf: row?.url_mcf ? (
          <a href={row?.url_mcf}>{row?.url_mcf}</a>
        ) : null,
        image_url_mcf: row?.image_url_mcf ? (
          <img className="img-thumbnail" src={row?.image_url_mcf} />
        ) : null,
      }
    })
  }, [rows, loading])

  return (
    <>

      { id === "create" ? (
        <Typography variant="h1">{t("sales_price_list_edit_title_new", "Myyntihinnastoluonnos")}</Typography>
      ) : (
        <Typography variant="h1">{t("sales_price_list_edit_title", "Myyntihinnaston muokkaus")} # {campaignErpId}</Typography>
      )}

      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        /*preHeader={preHeader}*/
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={false}
        /*
        filters={filters}
        setFilters={setFilters}
        */
        tableStickyHeader={true}
      />

    </>
  )
}

export default SalesPriceListEditProducts;
