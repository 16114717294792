import React, {useEffect, useState, useCallback} from "react";
import { useTranslation } from 'react-i18next';
import { Dropdown as BootstrapDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Checkbox } from "stories/forms";
import { Button, Typography, Link } from "stories/components";

const TableColumnToggle = ({tableName, userId, headers, setHeaders, setHeaderValue}) => {
  
  const { t } = useTranslation();
  const [viewDropdownOpen, setViewDropdownOpen] = useState(false);
  const viewDropdownToggle = () => {
    setViewDropdownOpen(prevState => !prevState);
  }
  
  const getUserDefaultColumns = () => {
    const columnsJson = localStorage.getItem(`table_columns_${tableName}_${userId}`)
    if (headers && headers.length > 0 && columnsJson != null) {
      const columns = JSON.parse(columnsJson)
      
      let _headers = [
        ...headers
      ]
      
      _headers && _headers.map((header, index) => {
        _headers[index].visible = columns[header?.key]
      })
      
      setHeaders(_headers)
    }
  }
  
  useEffect(() => {
    getUserDefaultColumns()
  }, [])
  
  const toggleTableColumn = (e, headerKey) => {
    // Update local storage default settings
    let columns = {}
    
    headers && headers.map(header => {
      if (header?.key === headerKey) {
        columns[header?.key] = e.target.checked
      }
      else {
        columns[header?.key] = header?.visible
      }
    })
    localStorage.setItem(`table_columns_${tableName}_${userId}`, JSON.stringify(columns));
    
    // Update table header
    setHeaderValue(headerKey, "visible", e.target.checked)
    
  }
  
  const renderHeaders = useCallback(() => {
    return headers && headers.map((header,index) => (
      <>
        <div className="px-2 py-2">
          <Checkbox
            id={`checkbox_${header?.key}`}
            name={`checkbox_${header?.key}`}
            onChange={(e) => toggleTableColumn(e, header?.key)}
            label={header?.label}
            checked={header?.visible}

          />
        </div>
      </>
    ))
  }, [headers])
  
  return (
    <>
      <div>
        <a className="sk_text_link small" variant="link" onClick={viewDropdownToggle} disabled={viewDropdownOpen}>
          {t("table_column_toggle_button", "Näkymä asetukset")}
        </a>
      </div>
      
      
      <BootstrapDropdown isOpen={viewDropdownOpen} toggle={viewDropdownToggle} inNavbar={true}>
      
        <DropdownToggle caret style={{display: "none",visibility:"hidden", top:"-32px"}}>
          Dropdown
        </DropdownToggle>
      
        <DropdownMenu style={{left:"-96px"}}>
          {renderHeaders()}
        </DropdownMenu>
        
      </BootstrapDropdown>
    </>
  )
}

export default TableColumnToggle;