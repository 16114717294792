import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'
import { generatePath } from "react-router";
import { getDate, getTime, getDateAndTime } from "services/datetime"

import { Row, Col } from "stories/layout";
import { 
  AlertModal,
  Breadcrumb,
  BreadcrumbItem, 
  Button, 
  Link, 
  Modal,
  Typography, 
} from "stories/components";
import { useAuthContext } from "contexts/AuthContext"
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { Input, SelectMulti } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import {
  httpGetAllMcfCampaigns
} from "services/campaigns"

const SalesPriceListEditBasic = ({history, location, match, id, campaignErpId, mcfCampaign, setMcfCampaign}) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext()
  const { notify } = useNotification();

  
  const getCampaignName = (lang) => {
    if (!mcfCampaign) return ""
    const translation = mcfCampaign.translations.find((translation) => translation?.language == lang)
    return translation ? translation.name : ""
  }

  /*
  const getCampaignName = (campaignId) => {
    if (!mcfCampaign) return ""
    return mcfCampaign[`name_${myUser?.language}`] || ""	
  }
  */
  const handleInputChange = ({ target }) => {
    const { value, name } = target;
    setMcfCampaign(s => ({
      ...s,
      [name]: value,
    }))
  }

  const getFilterOptions = useCallback((name) => {
    if (!mcfCampaign) return []
    return mcfCampaign[name] && mcfCampaign[name].map(item => {
      // return item // {value: item, label: item}
      return {value: item?.version_id.toString(), label: `${item?.version_id} NIMI PUUTTUU API`}
    })

  }, [mcfCampaign])
  
  const getSelectedOptions = useCallback((name) => {
    if (!mcfCampaign) return []
    return mcfCampaign[name] && mcfCampaign[name].map(item => {
      if (item?.is_visible === true) {
        return item?.version_id.toString()
      }
      else {
        return null
      }
      // return {value: item?.version_id, label: item?.version_id}
    })

  }, [mcfCampaign])

  // MCF visibilities
  const getVisibilitiesGroupOptions = useCallback(() => {
    if (!mcfCampaign) return []
    return mcfCampaign["visibilities"] && mcfCampaign["visibilities"].map(item => {
      return {value: item?.version_id.toString(), label: `${item?.version_id} NIMI PUUTTUU API`}
    })

  }, [mcfCampaign])
  
  const getSelectedVisibilitiesOptions = useCallback(() => {
    if (!mcfCampaign) return []
    return mcfCampaign["visibilities"] && mcfCampaign["visibilities"].map(item => {
      if (item?.is_visible === true) {
        // return item?.version_id.toString()
        return {value: item?.version_id.toString(), label: `${item?.version_id} NIMI PUUTTUU API`}
      }
      else {
        return null
      }
    })

  }, [mcfCampaign])

  // Customer groups
  const getCustomerGroupOptions = useCallback((name) => {
    if (!mcfCampaign) return []
    return mcfCampaign?.conditions?.customers?.group_ids.map(item => {
      return {value: item, label: item}
    });
  }, [mcfCampaign])
  
  const getSelectedCustomerGroupOptions = useCallback((name) => {
    if (!mcfCampaign) return []
    // return mcfCampaign?.conditions?.customers?.group_ids;
    return mcfCampaign?.conditions?.customers?.group_ids.map(item => {
      return {value: item, label: item}
    });
  }, [mcfCampaign])

  const setFieldValue = (name, value) => {
    setMcfCampaign(s => ({
      ...s,
      [name]: value,
    }))
  }

  return (
    <>
      { id === "create" ? (
        <Typography variant="h1">{t("sales_price_list_edit_title_new", "Myyntihinnastoluonnos")}</Typography>
      ) : (
        <Typography variant="h1">{t("sales_price_list_edit_title", "Myyntihinnaston muokkaus")} # {campaignErpId}</Typography>
      )}
      

      <Row>
        <Col>
          <Typography>{t("sales_price_list_edit_basic_mcf_campaign_id","MCF-kampanja-ID")}: {mcfCampaign?.id}</Typography>
          <Typography>{t("sales_price_list_edit_basic_created","Luotu")}: {getDateAndTime(mcfCampaign?.created_at)}</Typography>
        </Col>
      </Row>
      <Row>
        <Col>
          <Typography>{t("sales_price_list_edit_basic_name_en","Myyntihinnaston nimi (EN)")}</Typography>
          <Input type="text" name="name_en" value={getCampaignName("en")} onChange={handleInputChange} disabled={id === "create"} />
          
          <Typography>{t("sales_price_list_edit_basic_name_fi","Myyntihinnaston nimi (FI)")}</Typography>
          <Input type="text" name="name_en" value={getCampaignName("fi")} onChange={handleInputChange} disabled={id === "create"} />

          <div className="mb-4">
            <Typography inline>{t("sales_price_list_edit_basic_active","Aktiivinen")}</Typography>
            <Input type="checkbox" name="published" checked={mcfCampaign?.published} onChange={handleInputChange} disabled={id === "create"} />
          </div>

          <div className="d-flex">
            <div className="pr-2">
              <Typography>{t("sales_price_list_edit_basic_from_date","Voimassa alkaen")}</Typography>
              <Input type="date" name="from_date_date" value={mcfCampaign?.conditions?.time?.from_date ? mcfCampaign?.conditions?.time?.from_date.split("T")[0] : null} onChange={handleInputChange} disabled={id === "create"} />
              <Input type="time" name="from_date_time" value={getTime(mcfCampaign?.conditions?.time?.from_date)} onChange={handleInputChange} disabled={id === "create"} />
            </div>
            <div className="pr-2">
              <Typography>{t("sales_price_list_edit_basic_from_date","Voimassa alkaen")}</Typography>
              <Input type="date" name="to_date_date" value={mcfCampaign?.conditions?.time?.to_date ? mcfCampaign?.conditions?.time?.to_date.split("T")[0] : null} onChange={handleInputChange} disabled={id === "create"} />
              <Input type="time" name="to_date_time" value={getTime(mcfCampaign?.conditions?.time?.to_date)} onChange={handleInputChange} disabled={id === "create"} />
            </div>
          </div>

          <div className="mb-4">
            <Typography inline>{t("sales_price_list_edit_basic_description","Lisätietoja")}</Typography>
            <Input type="textarea" name="info" value={mcfCampaign?.info} onChange={handleInputChange} />
            {/* mcfCampaign?.page?.description */}
          </div>

        </Col>

        <Col>
          
            <div>
              <div className="d-flex align-items-start">
                <div>
                  <Typography inline>{t("sales_price_list_edit_basic_campaign_page","Kampanjasivu")}</Typography>
                </div>
                <div>
                  <Typography inline><Input type="checkbox" name="page_enabled" checked={mcfCampaign?.page?.enabled} onChange={handleInputChange} disabled={id === "create"} /></Typography>
                </div>
              </div>
            
              <Input type="text" name="link" value={mcfCampaign?.page?.mcf_campaign_page_link} onChange={handleInputChange} disabled={id === "create"} />
            </div>

          <Typography>{t("sales_price_list_edit_basic_campaign_code","Kampanjakoodi")}</Typography>
          <Input type="text" name="conditions_code" value={mcfCampaign?.conditions?.code} onChange={handleInputChange} disabled={id === "create"} />
          
          <Typography>{t("sales_price_list_edit_basic_versions","MCF-kauppaversio")}</Typography>
          <SelectMulti placeholder=" " name="visibilities" setFieldValue={setFieldValue} defaultValues={getSelectedVisibilitiesOptions()} options={getVisibilitiesGroupOptions()} />

          <Typography>{t("sales_price_list_edit_basic_customers","MCF-asiakasryhmä")}</Typography>
          <SelectMulti placeholder=" " name="customer_groups" setFieldValue={setFieldValue} defaultValues={getSelectedCustomerGroupOptions()} options={getCustomerGroupOptions()} />

          <Typography>{t("sales_price_list_edit_basic_erp_customers","ERP-asiakasryhmä")}</Typography>
          <SelectMulti disabled={true} placeholder=" " name="visibilities" setFieldValue={setFieldValue} defaultValues={[]} options={[]} />
        </Col>
      </Row>

      
    </>
  )
}

export default SalesPriceListEditBasic;
