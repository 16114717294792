import Frontpage from "views/dashboard/frontpage/Frontpage";
import Help from "views/dashboard/Help/Help";
import PurchaseOrders from "views/dashboard/purchaseOrders/PurchaseOrders";
import PurchaseOrderEdit from "views/dashboard/purchaseOrderEdit/PurchaseOrderEdit";
import PurchaseOrderReview from "views/dashboard/PurchaseOrderReview/PurchaseOrderReview";
import ProductsList from "views/dashboard/ProductsList/ProductsList";
import ProductEditView from "views/dashboard/ProductEditView/ProductEditView";
import ProductEdit from "views/dashboard/ProductEdit/ProductEdit";
import BrandsList from "views/dashboard/BrandsList/BrandsList";
import CategoriesList from "views/dashboard/CategoriesList/CategoriesList";
import CategoryEdit from "views/dashboard/CategoryEdit/CategoryEdit";
import CustomersList from "views/dashboard/CustomersList/CustomersList";
import CustomerPriceLists from "views/dashboard/CustomerPriceLists/CustomerPriceLists";
import CustomerUsersList from "views/dashboard/CustomerUsersList/CustomerUsersList";
import CustomerUserCreate from "views/dashboard/CustomerUserCreate/CustomerUserCreate";
import MyAccount from "views/dashboard/MyAccount/MyAccount";
import MyAccountPasswordChange from "views/dashboard/MyAccountPasswordChange/MyAccountPasswordChange";
import PriceListOrders from "views/dashboard/PriceListOrders/PriceListOrders";
import PriceListOrder from "views/dashboard/PriceListOrder/PriceListOrder";
import ProductComplaintsList from "views/dashboard/ProductComplaintsList/ProductComplaintsList";
import PurchaseOrderMcfReceipt from "views/dashboard/PurchaseOrderMcfReceipt/PurchaseOrderMcfReceipt";
import PurchasePriceCalculator from "views/dashboard/PurchasePriceCalculator/PurchasePriceCalculator";
import PurchasePriceCalculatorList from "views/dashboard/PurchasePriceCalculatorList/PurchasePriceCalculatorList";
import SalesPriceLists from "views/dashboard/SalesPriceLists/SalesPriceLists";
import Settings from "views/dashboard/Settings/Settings";
import SupplierEdit from "views/dashboard/SupplierEdit/SupplierEdit";
import SupplierUsersList from "views/dashboard/SupplierUsersList/SupplierUsersList";
import SuppliersList from "views/dashboard/SuppliersList/SuppliersList";
import SupplierUserCreate from "views/dashboard/SupplierUserCreate/SupplierUserCreate";
import SupplierPurchaseOrders from "views/dashboard/SupplierPurchaseOrders/SupplierPurchaseOrders";
import SupplierPurchaseOrderEdit from "views/dashboard/SupplierPurchaseOrderEdit/SupplierPurchaseOrderEdit";
import UsersList from "views/dashboard/UsersList/UsersList";
import UserEdit from "views/dashboard/UserEdit/UserEdit";
import Receipts from "views/dashboard/Receipts/Receipts";
import Reports from "views/dashboard/Reports/Reports";

import SalesPriceListEdit from "views/dashboard/SalesPriceListEdit/SalesPriceListEdit";

const Routes = (t, badges, myUser) => {
  
  return [
    {
      path: "/frontpage",
      name: t('nav_frontpage'),
      icon: "fas fa-home text-primary",
      component: Frontpage,
      layout: "/dashboard",
    },
    { // Myyntitilaukset (license)
      collapse: true,
      name: t("nav_company_sales_orders","[Myyntitilaukset]"),
      icon: "fas fa-suitcase text-primary",
      state: "ordersCollapse",
      layout: "/dashboard",
      badge: badges?.nav_purchase_orders_main,
      invisible: (myUser?.is_customer === true || myUser?.is_supplier === true),
      views: [
        /*
        {
          path: "/purchase_orders/new",
          name: t("nav_create_sales_order","Luo tilaus"),
          icon: "fas fa-medkit text-primary",
          miniName: "LT",
          component: PurchaseOrderEdit,
          layout: "/dashboard",
        },
        */
        {
          path: "/price_list_orders/list/sales/",
          name: t("nav_price_list_orders_sales","Myyntihistoria"),
          icon: "fas fa-handshake text-primary",
          miniName: "LT",
          // component: PriceListOrders, see hidden route
          layout: "/dashboard",
        },
        /*
        {
          path: "/sales_orders/list/draft",
          name: t("nav_sales_orders_pre_orders","Ennakkotilaukset"),
          icon: "fas fa-business-time text-primary",
          miniName: "LT",
          component: PurchaseOrderEdit,
          layout: "/dashboard",
        },
        */
        {
          path: "/sales_orders/price_lists",
          name: t("nav_sales_orders_price_list","Myyntihinnastot"),
          icon: "fas fa-map text-primary",
          miniName: "LT",
          component: SalesPriceLists,
          layout: "/dashboard",
        },
      ],
    },
    { // Myyntitilaukset (non-license) BEGIN
      collapse: true,
    name: t("nav_supplier_sales_orders","[Myyntitilaukset]"),
      icon: "fas fa-suitcase text-primary",
      state: "salesOrdersCollapse",
      layout: "/dashboard",
      badge: badges?.nav_purchase_orders_main,
      invisible: myUser?.is_supplier === false,
      views: [
        {
          // icon: "fas fa-store text-primary",
          path: "/supplier_sales_orders/list/received",
          name: t("nav_supplier_sales_orders_list_history","Myyntihistoria"),
          icon: "fas fa-handshake text-primary",
          miniName: "OT",
          layout: "/dashboard",
          badge: badges?.nav_supplier_sales_orders,
        },
      ],
    },
    {
      path: "/supplier_sales_orders/list/:tab",
      name: t("nav_supplier_sales_orders_list","Myyntitilaukset"),
      miniName: "OT",
      component: SupplierPurchaseOrders,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    },
    {
      path: "/supplier_sales_orders/edit/:purchaseOrderId?",
      name: t("nav_supplier_sales_orders_edit","Muokkaa myyntitilausta"),
      miniName: "A",
      component: SupplierPurchaseOrderEdit,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    }, // Myyntitilaukset (non-license) END
    { // Ostotilaukset (license) BEGIN
      collapse: true,
      name: t("nav_purchase_orders", "Ostotilaukset"),
      icon: "fas fa-shopping-cart text-primary",
      state: "ordersCollapse",
      layout: "/dashboard",
      badge: badges?.nav_purchase_orders_main,
      invisible: (myUser?.is_customer === true || myUser?.is_supplier === true),
      views: [
        {
          path: "/purchase_orders/new",
          name: t("nav_create_purchase_order","Luo tilaus"),
          icon: "fas fa-cart-plus text-primary",
          miniName: "LT",
          component: PurchaseOrderEdit,
          layout: "/dashboard",
        },
        {
          path: "/purchase_orders/list/draft",
          name: t("nav_purchase_orders_list","Ostohistoria"),
          icon: "fas fa-dolly text-primary",
          miniName: "OT",
          // component: PurchaseOrders, // see hidden routes
          layout: "/dashboard",
          badge: badges?.nav_purchase_orders_child,
        },
        {
          path: "/purchase_price_calculator_list",
          name: t("nav_purchase_price_calulator","Ostohintalaskuri"),
          icon: "fas fa-calculator text-primary",
          miniName: "OH",
          component: PurchasePriceCalculatorList,
          layout: "/dashboard",
        },
        /*
        {
          path: "/supplier_price_list",
          name: t("nav_purchase_supplier_price_list","Toimittajahinnastot"),
          icon: "fas fa-clipboard-list text-primary",
          miniName: "TH",
          component: CustomerPriceLists,
          layout: "/dashboard",
        },
        */
      ],
    }, // Ostotilaukset (license) END
    { // Ostotilaukset (non-license) BEGIN
      collapse: true,
      name: t("nav_purchase_orders", "Ostotilaukset"),
      icon: "fas fa-shopping-cart text-primary",
      state: "ordersCollapse",
      layout: "/dashboard",
      badge: badges?.nav_purchase_orders_main,
      invisible: myUser?.is_customer === false,
      views: [
        {
          path: "/price_list_orders/order/new", // Purchase order based on price lists
          name: t("nav_price_list_create_purchase_order","Luo tilaus"),
          icon: "fas fa-medkit text-primary",
          miniName: "LT",
          // component: PriceListOrder,
          layout: "/dashboard",
        },
        {
          path: "/price_list_orders/list/purchases/",
          name: t("nav_price_list_orders_purchases_history","Ostohistoria"),
          icon: "fas fa-handshake text-primary",
          miniName: "LT",
          // component: PriceListOrders, see hidden route
          layout: "/dashboard",
        },
        {
          path: "/purchase_price_calculator_list",
          name: t("nav_purchase_price_calulator","Ostohintalaskuri"),
          icon: "fas fa-calculator text-primary",
          miniName: "OH",
          component: PurchasePriceCalculatorList,
          layout: "/dashboard",
          invisible: (myUser?.is_supplier === true || myUser?.is_customer === true), // If not LICENSE OWNER
        },
        {
          path: "/supplier_price_list",
          name: t("nav_purchase_supplier_price_list","Toimittajahinnastot"),
          icon: "fas fa-clipboard-list text-primary",
          miniName: "TH",
          component: CustomerPriceLists,
          layout: "/dashboard",
          badge: badges?.new_campaigns_available, //jk
        },
      ],
    }, // Ostotilaukset (non-license) END
    {
      collapse: true,
      name: t("nav_products","Tuotteet"),
      icon: "fas fa-folder text-primary",
      state: "productsCollapse",
      layout: "/dashboard",
      invisible: (myUser?.is_supplier === true || myUser?.is_customer === true), // If not LICENSE OWNER
      views: [
        {
          path: "/products/new",
          name: t("nav_create_product","Luo tuote"),
          icon: "fas fa-folder-plus text-primary",
          miniName: "PN",
          component: ProductEdit,
          layout: "/dashboard",
        },
        {
          path: "/products/list",
          name: t("nav_products_list","Tuotelista"),
          icon: "fas fa-list-alt text-primary",
          miniName: "A",
          component: ProductsList,
          layout: "/dashboard",
        },
        {
          path: "/brands/list",
          name: t("nav_brands","Tuotemerkit"),
          icon: "fas fa-trademark text-primary",
          miniName: "A",
          component: BrandsList,
          layout: "/dashboard",
        },
        {
          path: "/categories/list",
          name: t("nav_categories","Tuoteryhmät"),
          icon: "fas fa-sitemap text-primary",
          miniName: "A",
          component: CategoriesList,
          layout: "/dashboard",
        },
      ],
    },
    {
      path: "/complaints/list",
      name: t("nav_complaints","Reklamaatiot"),
      icon: "fas fa-expand text-primary",
      component: ProductComplaintsList,
      layout: "/dashboard",
      invisible: (myUser?.is_supplier === true || myUser?.is_customer === true), // If not LICENSE OWNER
    },
    {
      path: "/customers/list",
      name: t("nav_customers_list","Asiakkaat"),
      icon: "fas fa-store text-primary",
      component: CustomersList,
      layout: "/dashboard",
      invisible: (myUser?.is_supplier === true || myUser?.is_customer === true), // If not LICENSE OWNER
    },
    {
      path: "/suppliers/list",
      name: t("nav_suppliers","Tavarantoimittajat"),
      icon: "fas fa-industry text-primary",
      component: SuppliersList,
      layout: "/dashboard",
      invisible: (myUser?.is_supplier === true || myUser?.is_customer === true), // If not LICENSE OWNER
    },
    /*
    {
      path: "/reports",
      name: t("nav_reports","Raportit"),
      icon: "far fa-file-alt text-primary",
      component: Reports,
      layout: "/dashboard",
    },
    {
      path: "/receipts",
      name: t("nav_receipts","Kuitit"),
      icon: "fas fa-receipt text-primary",
      component: Receipts,
      layout: "/dashboard",
    },
    */
    {
      path: "/users/list",
      name: t("nav_users","Käyttäjähallinta"),
      icon: "fas fa-users text-primary",
      component: UsersList,
      layout: "/dashboard",
      invisible: (myUser?.is_supplier === true || myUser?.is_customer === true), // If not LICENSE OWNER
    },
    {
      path: "/users/new",
      name: t("nav_new_user","Uusi käyttäjä"),
      icon: "ni ni-single-02 text-primary",
      component: UserEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/users/edit/:id/",
      name: t("nav_edit_user","Muokkaa käyttäjää"),
      icon: "ni ni-single-02 text-primary",
      component: UserEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    /*
    {
      hr: true,
    },
    */
    {
      path: "/myaccount",
      name: t("nav_myaccount","Omat tiedot"),
      icon: "fas fa-user text-primary",
      component: MyAccount,
      layout: "/dashboard",
      exact: true,
    },
    {
      path: "/myaccount/password",
      name: t("nav_myaccount_password_change","Vaihda salasanaa"),
      icon: "fas fa-user text-primary",
      component: MyAccountPasswordChange,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/products/edit/:productId/:tab/:productVariationId?",
      name: t("nav_product_edit","Muokkaa tuotetta"),
      icon: "ni ni-single-02 text-primary",
      component: ProductEditView,
      layout: "/dashboard",
      invisible: true,
      exact: false,
      // permissions: 3,
    },
    {
      path: "/purchase_orders/list/:tab",
      name: t("nav_purchase_orders","Ostotilaukset"),
      miniName: "A",
      component: PurchaseOrders,
      invisible: true,
      exact: false,
      layout: "/dashboard",
    },
    {
      path: "/purchase_orders/edit/:purchaseOrderId?",
      name: t("nav_purchase_order_edit","Muokkaa ostotilausta"),
      miniName: "A",
      component: PurchaseOrderEdit,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    },
    {
      path: "/purchase_orders/review/:purchaseOrderId?",
      name: t("nav_purchase_order_edit","Muokkaa ostotilausta"),
      miniName: "R",
      component: PurchaseOrderReview,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    },
    {
      path: "/purchase_price_calculator/:purchaseOrderId?",
      name: t("nav_purchase_price_calulator","Ostohintalaskuri"),
      miniName: "OH",
      component: PurchasePriceCalculator,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/purchase_order_mcf_receipt/:purchaseOrderId?",
      name: t("nav_purchase_order_mcf_receipt","Vie tiedot MCF"),
      miniName: "OH",
      component: PurchaseOrderMcfReceipt,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/categories/new",
      name: t("nav_category_new","Uusi kategoria"),
      icon: "ni ni-single-02 text-primary",
      component: CategoryEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/categories/edit/:id/",
      name: t("nav_category_edit","Muokkaa kategoriaa"),
      icon: "ni ni-single-02 text-primary",
      component: CategoryEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/suppliers/new",
      name: t("nav_supplier_new","Uusi tavarantoimittaja"),
      icon: "ni ni-single-02 text-primary",
      component: SupplierEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/suppliers/edit/:id/",
      name: t("nav_supplier_edit","Muokkaa tavarantoimittajaa"),
      icon: "ni ni-single-02 text-primary",
      component: SupplierEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/suppliers/users/list/:id/",
      name: t("nav_supplier_users","Tavarantoimittajan käyttäjälista"),
      icon: "ni ni-single-02 text-primary",
      component: SupplierUsersList,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/suppliers/users/create/:id/",
      name: t("nav_supplier_user_create","Luo tavarantoimittajakäyttäjä"),
      icon: "ni ni-single-02 text-primary",
      component: SupplierUserCreate,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    { // Customers BEGIN
      path: "/customers/users/list/:id/",
      name: t("nav_customers_users","Asiakkaan käyttäjälista"),
      icon: "ni ni-single-02 text-primary",
      component: CustomerUsersList,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/customers/users/create/:id/",
      name: t("nav_customers_user_create","Luo asiakaskäyttäjä"),
      icon: "ni ni-single-02 text-primary",
      component: CustomerUserCreate,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    }, // Customers END
    {
      path: "/sales_orders/price_list_edit/:id/:tab/",
      name: t("nav_sales_orders_price_list_edit","Myyntihinnasto"),
      icon: "fas fa-map text-primary",
      miniName: "LT",
      component: SalesPriceListEdit,
      layout: "/dashboard",
      invisible: true,
    },
    // Price list orders BEGIN
    {
      path: "/price_list_orders/list/:order_type/:tab?/", // order_type: sales=myynti, purchases=osto
      // name: t("nav_sales_orders_history","Myyntihistoria"),
      icon: "fas fa-handshake text-primary",
      miniName: "LT",
      component: PriceListOrders,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/price_list_orders/order/:orderId?",
      name: t("nav_purchase_order_edit","Muokkaa ostotilausta"),
      miniName: "A",
      component: PriceListOrder,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    },
    // Price list orders END
  ];
}

export default Routes;
