import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table, THead, Pagination } from "stories/tables";
import { Checkbox } from "stories/forms";
import { Spinner } from "stories/components"

import './TableAuto.css';

export const TableAuto = ({ 
    checkboxes = false,
    checked = [],
    setChecked,
    filters,
    headers,
    headerColor = "dark",
    headerTextLength = 8,
    rows,
    className,
    loading,
    pagination = false,
    paginationPrevious,
    paginationNext,
    paginationOffset,
    paginationLimit,
    paginationCount,
    setFilters,
    showId = false,
    tableSmall = true,
    tableStickyHeader = false,
    tableStriped = true,
    preHeader,
    secondHeader,
    showPaginationLimitSelect = true,
    showVerticalBorders = true,
    sortBackend = true,
    sorting,
    setSorting,
    ...rest 
  }) => {
  
  const { t } = useTranslation();
  const [checkedAll, setCheckedAll] = useState(false)
  
  // Ordering
  const getCurrentOrdering = () => {
    if (sortBackend === true) {
      let orderingField = "";
      let directionAsc = true;
      let ordering = filters?.ordering;
      
      if (ordering) {
        if (ordering.includes("-")) {
          directionAsc = false;
        }
        orderingField = ordering.replace("-","")
      }
      
      return {
        field: orderingField,
        directionAsc: directionAsc,
      }
    }
    else {
      return {
        field: sorting?.sortingKey,
        directionAsc: !sorting?.sortingReverse,
      }
    }
  }
  
  const getSortingStatus = (headerObject) => {
    if (headerObject?.sortable === true) {
      const currentOrdering = getCurrentOrdering();
      if (currentOrdering.field === headerObject?.sortingKey) {
        if (currentOrdering.directionAsc) {
          return (
            <i className="fas fa-angle-up"></i>
          )
        }
        else {
          return (
            <i className="fas fa-angle-down"></i>
          )
        }
      }
    }
    return ""
  }
  
  const handleSorting = (header) => {
    let directionAsc = true;
    const currentOrdering = getCurrentOrdering();
    if (currentOrdering?.field === header.sortingKey) {
      directionAsc = !currentOrdering?.directionAsc
    }
    
    if (sortBackend === true) {
      let orderingFilter = header?.sortingKey;
      if (directionAsc === false) {
        orderingFilter = "-"+orderingFilter;
      }
      
      setFilters(s => ({
        ...s,
        ordering: orderingFilter,
      }))
    }
    else {
      setSorting({
        ...header,
        sortingKey: header?.sortingKey,
        sortingReverse: !directionAsc,
      })
    }
  }
  
  // Ordering END
  
  // Checkboxes
  
  const handleChecked = (id) => (e) => {
    
    let _checked = checked;
    const index = _checked.indexOf(id);
    
    if (index > -1) {
      _checked.splice(index, 1);
    }
    else {
      _checked.push(id)
    }
    
    setChecked([
      ..._checked
    ])
    
  }
  
  const handleToggleCheckAll = () => (e) => {
    
    setCheckedAll(s => !s)
    
    if (e?.target?.checked === true) {
      let _checked = []
      rows && rows.map((row, index) => {
        const checkId = row?.checkId ? row?.checkId : null;
        if (checkId && checkId != -1) {
          _checked.push(checkId)
        }
        
      })
      
      setChecked([
        ..._checked
      ])
    }
    else {
      setChecked([])
    }
  }
  
  const isChecked = (id) => {
    return checked.reduce((result, item) => {
      if (item === id) { result = true }
      return result
    }, false)
    
  }
  
  // Checkboxes END
  
  const renderHeader = () => {
    return headers && headers.filter(item => {return item?.visible!==false}).map((header, index) => {
      const label = header?.label ? (header?.labelSlice === true && header?.label.length > 0 ? header?.label.slice(0,headerTextLength) : header?.label) : ""
      const secondRow = header?.secondRow
      if (header?.sortable) {
        return (
          <th key={index} title={header?.label} className={`sk_table_header ${header?.className ? header?.className : ""}`}><a className="table_header_link" onClick={() => handleSorting(header)}>{label} {getSortingStatus(header)}</a><br />{secondRow ? `${secondRow}` : null}</th>
        )
      }
      else {
        return (
          <th key={index} title={header?.label} className={`sk_table_header ${header?.className ? header?.className : ""}`}>{label}<br />{secondRow ? `${secondRow}` : null}</th>
        )
      }
      
    })
  }

  const renderPreHeader = () => {
    return headers && headers.filter(item => {return item?.visible!==false}).map((header, h) => (
      <th key={h} className="sk_pre_header">{preHeader[header?.key]?.content}</th>
    ))
  }
  
  const renderSecondHeader = () => {
    return headers && headers.filter(item => {return item?.visible!==false}).map((header, j) => (
      <th key={j} className="sk_second_header">{secondHeader[header?.key]?.visible ? secondHeader[header?.key]?.content : null}</th>
    ))
  }
  
  const renderRows = () => {
    if (!rows || rows.length === 0) {
      return (
        <tr><td colspan={2+headers.length}>{t("Ei tuloksia")}</td></tr>
      )
    }
    
    return rows && rows.map((row, index) => {
      
      const rowKey = row?.id ? row?.id : `ind-${index}`;
      const checkId = row?.checkId ? row?.checkId : index;
      const checked = isChecked(checkId);
      
      if (row?.subtitle) {
        return (
          <tr key={rowKey} className={`sk_subtitle ${row?.className ? row?.className : ""}`}>
            <td>
            
            </td>
          </tr>
        )
      }
      else {
        return (
          <tr key={rowKey} className={`${row?.className ? row?.className : ""} ${row?.loading === true ? 'sk_tr_disabled' : ''}`}>
            { showId && (
              <th>
              {row?.id}
              </th>
            )}
            { checkboxes && (
              <th>
                {row?.isSubtitle === true ? (
                  <>&nbsp;</>
                ) : (
                  <>
                  { checkId && checkId != -1 ? (<Checkbox 
                    id={`id_checkbox_${index}`}
                    name={`checkbox_${index}`}
                    checked={checked}
                    value={`checkbox_${index}`}
                    onChange={handleChecked(checkId)}
                  />
                  ) : null}
                  </>
                )}
              </th>
            )}
            {headers && headers.filter(item => {return item?.visible!==false}).map((header, j) => {
              // jk
              const cellAttributes = row[`${header?.key}_cell_attributes`] ? row[`${header?.key}_cell_attributes`] : {};
              return (
                <td key={j} className={`${header?.className ? header?.className : ""} ${row[header?.key]?.className ? row[header?.key]?.className : ""}`} {...cellAttributes}>{row[header?.key]?.content ? row[header?.key]?.content : row[header?.key]}</td>
              )
            })}
          </tr>
        )
      }
    })
  }
  
  return (
    <>
      <Table
        className={`sk_table table_auto ${tableSmall ? "table-sm" : ""} ${tableStriped ? "table-striped" : ""} ${tableStickyHeader ? "table_sticky_header" : ""} ${className ? className : ""}`}
        showVerticalBorders={showVerticalBorders}
      >

        { preHeader && (
          <THead color="">
            <tr>
            { showId && (
              <th>&nbsp;</th>
            )}
            { checkboxes && (
              <th>&nbsp;</th>
            )}
            {renderPreHeader()}
            </tr>
          </THead>
        )}

        <THead color={headerColor}>
          
          <tr>
            { showId && (
              <th>
              &nbsp;
              </th>
            )}
            { checkboxes && (
              <th>
                <Checkbox 
                  id="checkbox_all"
                  name="checkbox_all"
                  value="checkbox_all"
                  checked={checkedAll} 
                  onChange={handleToggleCheckAll()} 
                />
              </th>
            )}
            {renderHeader()}
          </tr>
          { secondHeader && (
            <tr>
            { showId && (
              <th>&nbsp;</th>
            )}
            { checkboxes && (
              <th>&nbsp;</th>
            )}
            {renderSecondHeader()}
            </tr>
          )}
        </THead>
        <tbody>
        { loading ? (
          <tr><td><Spinner /></td></tr>
        ) : 
          renderRows()
        }
        </tbody>
      </Table>
      { pagination && !loading && (
        <Pagination 
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={paginationOffset}
          paginationLimit={paginationLimit}
          paginationCount={paginationCount}
          setFilters={setFilters}
        />
      )}
    </>
    
  )
};

TableAuto.propTypes = {
  headers: PropTypes.array,
  headerColor: PropTypes.oneOf(['light','dark']),
  rows: PropTypes.array,
  checkboxes: PropTypes.bool,
  checked: PropTypes.array,
  sortBackend: PropTypes.bool,
  pagination: PropTypes.bool,
  preHeader: PropTypes.object,
  secondHeader: PropTypes.object,
  showId: PropTypes.bool,
  showPaginationLimitSelect: PropTypes.bool,
  showVerticalBorders: PropTypes.bool,
  tableSmall: PropTypes.bool,
  tableStickyHeader: PropTypes.bool,
  tableStriped: PropTypes.bool,
};

