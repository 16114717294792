import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Breadcrumb, BreadcrumbItem, Button, Link, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Checkbox, Input } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { httpRegisterUser } from "services/users" 
import { httpCreateCustomerUserRole } from "services/customers" 
import { useAppContext } from "contexts/AppContext"
import { isObject } from "services/helpers"

require('yup-phone');

const CustomerUserCreate = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      history,
      t,
      customerId,
   } = props;
   
   const { enums } = useAppContext()
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push(`/dashboard/customers/users/list/${customerId}/`);
  }
  
  const handleDelete = () => {
    const result = window.confirm(t("Haluatko varmasti poistaa?"));
    if (result) {
      /*
      httpDeleteUser(values?.id).then(res => {
        if (isStaff) {
          history.push("/dashboard/users");
        }
        else {
          history.push("/dashboard/myuser");
        }
      })
      */
    }
  }
  
  const getRoleOptions = useCallback(() => {
    const roles = enums?.suppliers?.supplier_user_role?.role;
    return roles && roles.map(role => {
      return (
        <option value={role.key}>{role.value}</option>
      )
    })
  }, [enums])
  
  return (
    <>
      <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
        <BreadcrumbItem>
          <Link to={`/dashboard/customers/list`}>
            {t("Asiakkaat")}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`/dashboard/customers/users/list/${customerId}/`}>
            {t("Käyttäjät")}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          {t("Lisää käyttäjä")}
        </BreadcrumbItem>
      </Breadcrumb>
      
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Luo asiakaskäyttäjä")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("email")}
                label={t("Sähköposti/käyttäjätunnus")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("first_name")}
                label={t("Etunimi")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("last_name")}
                label={t("Sukunimi")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input {...getFormikInputProps("role")} label={t("Rooli")} type="select">
                {getRoleOptions()}
              </Input>
            </Col>
          </Row>
          
        </Col>
      
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        {/*
        <Col className="mb-3 text-center">
          <Button onClick={handleDelete} variant="danger">{t("Poista")}</Button>
        </Col>
        */}
        <Col className="mb-3 text-right">
          <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("Luo käyttäjä")}</Button>
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
    </>
  );
}

const defaultValues = {
  role: 1,
  // activated: moment().set({hour:23,minute:59,second:59,millisecond:999}).format("YYYY-MM-DD"),
}

 const CustomerUserCreateFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        first_name: Yup.string().required(required).nullable(),
        last_name: Yup.string().required(required).nullable(),
        email: Yup.string().email(t('Tarkista sähköpostiosoitteen muoto')).required(required),
        // phone: Yup.string().phone("FI", false,'Puhelinnumero tulee olla muodossa esim. +358501234567').required(required).nullable(),
        // activated: Yup.date().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      const pwdChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      const pwdLen = 32;
      const randPassword = Array(pwdLen).fill(pwdChars).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
      
      let data = {
        ...values,
        username: values?.email,
        password: randPassword,
        send_invitation_email: false,
        send_activaton_email: false,
      };
      
      httpRegisterUser(data).then(res => {
        let dataUserRole = {
          user: res?.data?.id,
          customer: parseInt(props.customerId),
          role: values?.role,
          send_invitation_email: true,
        }
        httpCreateCustomerUserRole(props.customerId, dataUserRole).then(res => {
          props.notify({ type: "success", title:t("Käyttäjä"), message:t("Luotu onnistuneesti")});
          props.history.push(`/dashboard/customers/users/list/${props?.customerId}/`);
        }, error => {
          if (error?.data) {
            if (error?.data?.EMAIL_SEND_FAILED) {
              props.notify({ type: "warning", title:t("Käyttäjä"), message:t("Käyttäjä luotu, mutta kutsun lähetys epäonnistui. Tarkista sähköpostiosoite.")});
              props.history.push(`/dashboard/customers/users/list/${props?.customerId}/`);
            }
            else {
              if (error?.status === 400 && isObject(error?.data)) {
                setErrors({
                  general: t("check_form_errors","Tarkista lomakkeen virheet"),
                  ...error?.data
                })
              }
              else {
                setErrors({
                  general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
                })
              }
            } 
            
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        }).finally(() => {
          setSubmitting(false);
        })
        
      }, error => {
        if (error?.status === 400 && error?.data) {
          setErrors({
            ...error?.data,
            general: t("check_form_errors","Tarkista lomakkeen virheet"),
          })
        }
        else {
          setErrors({
            general: `${t("error_unknown", "Tuntematon virhe")} ${JSON.stringify(error?.data)}`,
          })
        }
      }).finally(() => {
        setSubmitting(false);
      })
      
      
    },
    displayName: "BasicForm"
  
  })(CustomerUserCreate)
    
    
const CustomerUserCreateView = ({match, history}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [customerId, setCustomerId] = useState();
  
  useEffect(() => {
    if (match && match?.params?.id) {
      setCustomerId(match?.params?.id)
    }
  }, [match])
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <CustomerUserCreateFormik t={t} history={history} preSetValues={preSetValues} notify={notify} customerId={customerId} />
  )
  

}
  

export default CustomerUserCreateView;
