import http from './api'

const url = '/customers/me/campaigns'

export const httpGetCustomerCampaigns = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params,
  })
}
export const httpGetCustomerCampaignProducts = (params) => {
  return http({
    url: `/customers/me/customer_campaign_products/` ,
    method: 'GET',
    params,
  })
}

export const httpUpdateCustomerCampaigns = (params) => {
  return http({
    url: `/customers/me/update_customer_campaigns/` ,
    method: 'GET',
    params,
  })
}
