import React, { useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

// core components
import { Checkbox, Input } from "stories/forms";
import { Button, Link, Typography } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"

import { httpLogin } from "services/auth"
import { httpRegisterUser } from "services/users"
import { httpLoginMcf } from "services/customers"
import { useStoreActions } from 'easy-peasy';
import { useAuthContext } from "contexts/AuthContext";
import { useAppContext } from "contexts/AppContext"

const generatePassword = () => {
  const _length = 12;
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?";
  let password = "";
  for (let i = 0; i < _length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
  }
  return password;
}

const Login = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
     setFieldValue,
   } = props;
   
   const {
      history,
      t,
      enums,
   } = props;
   
   const getCreateMcfAccountURL = () => {
    /*
    enums = {
      "admin_data": {
          "admin_param": {
              "values": [
                  {
                      "value": "https://tradesoftkauppa.fi",
                      "key": "CREATE_MCF_ACCOUNT_HERE"
                  }
              ]
          }
      }
    }
    */

    let url = enums?.admin_data?.admin_param?.values?.find(x => x.key === "CREATE_MCF_ACCOUNT_HERE")?.value;
    return url;
   }
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/");
  }
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(() => handleSubmit())
  }

  
  
  return (
    <>
      <Container className="mt-8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card variant="primary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">{t('Kirjaudu sisään')}</Typography>
                  <Typography variant="p">{t('Tervetuloa takaisin')}</Typography>
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <form onSubmit={onSubmit}>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("email")}
                      label={t("Sähköposti")}
                      type="email"
                      required
                    />
                  </Col>
                </Row>

                
                { values?.first_time === false ? (
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("password")}
                      label={t("Salasana")}
                      type="password"
                      required={values?.first_time === false}
                    />
                  </Col>
                </Row>
                ) : null }

                <Row>
                  <Col className="mb-0">
                    <div className="row justify-content-start">
                      <div className="ml-3 mb-3">
                        <Checkbox 
                          id="first_time"
                          name="first_time"
                          checked={values?.first_time}
                          onChange={(e) => {setFieldValue("first_time", e.target.checked)}}
                          value="first_time"
                          errorText={touched["first_time"] ? errors["first_time"] : ""}
                          label={t('login_first_time','First time logging in?')}
                        />
                      </div>
                      
                    </div>
                  </Col>
                </Row>

                { values?.first_time ? (
                <Row>
                  <Col className="mb-3">
                    <div className="row justify-content-start">
                      <div className="ml-3 mb-3">
                        <Checkbox 
                          id="mcf_account"
                          name="mcf_account"
                          checked={values?.mcf_account}
                          onChange={(e) => {setFieldValue("mcf_account", e.target.checked)}}
                          value="mcf_account"
                          required
                          errorText={touched["mcf_account"] ? errors["mcf_account"] : ""}
                          label={t('login_mcf_account','Do you have MyCashflow account? (required)')}
                        />
                      </div>
                    </div>
                    <Link isExternal href={getCreateMcfAccountURL()} >{t('login_create_mcf_account','Create MCF account here')}</Link>
                  </Col>
                </Row>
                ) : null }
                
                <Row>
                  <Col className="mb-3">
                  {/* <Typography variant="small">Ei tunnuksia? <Link to="/auth/register">Luo tästä</Link></Typography> */}
                  </Col>
                  <Col className="mb-3 text-right">
                    <Typography variant="small">{t('Salasana unohtunut?')} <Link to="/auth/password/forgot">{t('Palauta tästä')}</Link></Typography>
                  </Col>
                </Row>
                
                <div className="text-center text-muted mb-4">
                  { values?.first_time === false ? (
                    <Button disabled={isSubmitting || (values?.first_time === true && values?.mcf_account === false)} type="submit">{t('Kirjaudu')}</Button>
                  ) : (
                    <Button color="success" disabled={isSubmitting || (values?.first_time === true && values?.mcf_account === false)} type="submit">{t('Create FlowM8 account')}</Button>
                  )}
                </div>
                
                { Boolean(errors["general"]) && (
                  <div className="text-center text-muted mb-4">
                    <Typography className="text-danger">{errors["general"]}</Typography>
                  </div>
                )}
              
              </form>
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

const defaultValues = {
  email: '',
  password: '',
  first_time: false,
  mcf_account: false,
}

 const LoginFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      
      return Yup.object().shape({
        email: Yup.string().email(t('Tarkista sähköpostiosoitteen muoto')).required(required),
        // password: Yup.string().required(false),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, notify} = props;

      // MCF login first time?
      if (values?.first_time) {
        httpLoginMcf({email: values?.email}).then(res => {
          setSubmitting(false);
          if (res?.data) {
            console.log("MCF login first time", res.data);
            if (res?.data?.token) {
              let { token } = res.data;
              // Register a new ERP user with MCF token
              httpRegisterUser({email: values?.email, username: values?.email, password: generatePassword(), token:token, send_invitation_email: true}).then(res => {
                console.log("httpRegisterUser", res);
                notify({
                  type: "success",
                  message:t("Käyttäjätili luotu onnistuneesti. Tarkista sähköpostisi ja aktivoi tili.")
                })
                props.history.push(`/`);
              }, error => {
                console.log("httpRegisterUser", error);
                setErrors({
                  ...error?.data,
                  general: t('Virhe. Ota tarvittaessa yhteys ylläpitoon.'),
                })
              })

              // props.history.push(`/auth/register_mcf_user/?token=${token}&email=${values?.email}`);
            }
          }
        }, error => {
          setSubmitting(false);
          console.log("httpLoginMcf", error);

          if (error?.status === 400) {
            if (error?.data?.errorCode == "UNABLE_TO_LOGIN_CUSTOMER_MCF") {
              setErrors({
                general: error?.data?.message,
              })
            }
            else {
              setErrors({
                ...error?.data,
                general: `${t('Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error?.data)}`,
              })
            }
          }
          else {
            setErrors({
              general: `${t('Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,
            })
          }
        })
        return;
      }

      // Normal ERP login
      httpLogin(values?.email, values?.password).then(res => {
        setSubmitting(false);
        if (res?.data) {
          let { access, refresh } = res.data;
          localStorage.setItem("accessToken", access);
          localStorage.setItem("refreshToken", refresh);
          props.handleResolve()
        }
        else {
          setErrors({
            general: t('Väärä tunnus tai salasana'),
          })
        }
      }, error => {
        setSubmitting(false);
        const details = error?.data?.detail ? error?.data?.detail : t("Väärä tunnus tai salasana");
        
        if (error?.status === 401) {
          if (error?.data?.detail === "No active account found with the given credentials") {
            setErrors({
              general: t("No active account found with the given credentials"),
            })
          }
          else {
            setErrors({
              general: error?.data?.detail,
            })
          }
        }
        else if (error?.status === 400) {
          if (error?.data?.errorCode == "USER_NOT_ACTIVATED") {
            setErrors({
              general: `${t('Käyttäjätunnusta ei ole aktivoitu')}`,
            })
          }
          else {
            setErrors({
              general: `${t('Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error?.data)}`,
            })
          }
        }
        else {
          setErrors({
            general: `${t('Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(Login)
    
    
const LoginView = ({match, history}) => {
  // API requests here
  
  const { t } = useTranslation();
  const { enums } = useAppContext()
  const { getMyUser } = useAuthContext();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [infoAlert, setInfoAlert] = useState();
  // const login = useStoreActions((actions) => actions.auth.login);
  
  const handleModalConfirm = () => {
    setInfoAlert(null);
    getMyUser();
    // history.push("/dashboard/frontpage");
  }
  
  const handleResolve = () => {
    notify({ title:t("Käyttäjä"), message:t("Kirjautuminen onnistui")})
    handleModalConfirm();
    /*
    setInfoAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Lähetetty"
        onConfirm={() => handleModalConfirm()}
        onCancel={() => handleModalConfirm()}
        btnSize=""
      >
        Kutsu lähetetty onnistuneesti
      </ReactBSAlert>
    )
    */
  }
  
  return (
    <>
      <LoginFormik history={history} t={t} handleResolve={handleResolve} notify={notify} enums={enums} />
      {infoAlert}
    </>
  )
}
  

export default LoginView;
