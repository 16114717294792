import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'
import { generatePath } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";

import { Row, Col } from "stories/layout";
import { 
  AlertModal,
  Breadcrumb,
  BreadcrumbItem, 
  Button, 
  Link, 
  Modal,
  Typography, 
} from "stories/components";
import { useAuthContext } from "contexts/AuthContext"
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { Input, SelectMulti } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import {
  httpGetCampaigns, // Existing MCF campaigns saved to ERP
  httpGetCampaign,
  httpCreateCampaign,
  httpUpdateCampaign,
  httpGetAllMcfCampaigns, // Available MCF campaigns
  httpGetMcfCampaign
} from "services/campaigns"
import SalesPriceListEditBasic from "./SalesPriceListEditBasic";
import SalesPriceListEditProducts from "./SalesPriceListEditProducts";

const SalesPriceListEdit = ({history, location, match}) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext()
  const { notify } = useNotification();
  const [campaignErpId, setCampaignErpId] = useState()
  const [mcfCampaign, setMcfCampaign] = useState()

  const getAvailableMcfCampaign = (id) => {
    console.log("Get MCF Campaign", id)
    httpGetMcfCampaign(id)
    .then((response) => {
      if (response.data.length === 0) {
        notify("No campaign found", "error")
        return
      }
      const campaign = response.data //.find((campaign) => campaign.id_mcf == id)
      console.log("MCF Campaign", campaign);
      setMcfCampaign(campaign)
    })
    .catch((error) => {
      console.log("MCF Campaign error", error)
    })
  }

  const getErpMcfCampaign = (campaignErpId) => {
    console.log("Get MCF Campaign", campaignErpId)
    // setLoading(s => s+1)
    httpGetCampaign(campaignErpId).then(res => {
      console.log("MCF Campaign:", res)
      const campaign = res.data //.find((campaign) => campaign.id == campaignErpId)
      console.log("MCF Campaign", campaign);
      setMcfCampaign(campaign)
    }, error => {
      console.error('httpGetCampaigns error:', error);
    }).finally(() => {
      // setLoading(s => s-1)
    })
  }

  useEffect(() => {
    console.log("match", match)
    let isNew = false;
    if (match?.params?.id == "create") {
      isNew = true;
    }
    else {
      isNew = false;
    }

    if (isNew === true) {
      const keys = queryString.parse(location.search)
      console.log("Keys", keys)
      if (keys?.mcf_campaign) {
        getAvailableMcfCampaign(keys.mcf_campaign)
      } 
    }
    else {
      getErpMcfCampaign(match?.params?.id)
      setCampaignErpId(match?.params?.id)
    }
    
  }, [location])

  const submit = () => {
    console.log("Submit", mcfCampaign)
    if (mcfCampaign?.id) {
      // Update
      httpUpdateCampaign(mcfCampaign.id, mcfCampaign).then(res => {
        notify({
          type: "success",
          message: "Hinnasto päivitetty"
        })
        history.push(`/dashboard/sales_orders/price_lists`)
      }, error => {
        notify({
          type: "success",
          message: "Hinnasto päivitetty"
        })
      })
    }
    else {
      // Create
      httpCreateCampaign(mcfCampaign).then(res => {
        notify({
          type: "success",
          message: "Hinnasto luotu"
        })
        history.push(`/dashboard/sales_orders/price_lists`)
      }, error => {
        notify({
          type: "danger",
          message: "Hinnaston luonti epäonnistui"
        })
      })
    }
  }
  
  return (
    <>
      <ul className="nav nav-tabs mb-2">
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "basic" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              id: match?.params?.id,
              tab: "basic"
            })}
          ><i className="fas fa-exclamation-circle"></i> {t("sales_price_list_edit_basic_tab","Perustiedot")}</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${(match?.params?.tab === "products") ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              id: match?.params?.id,
              tab: "products"
            })}
          ><i className="fas fa-briefcase"></i> {t("sales_price_list_edit_products_tab","Hinnastotuotteet")}</Link>
        </li>
      </ul>

      <Switch>
        <Route
          path={
            generatePath(match.path, {
              id: match?.params?.id,
              tab: "basic"
            })
          }
          exact
          render={props => <SalesPriceListEditBasic 
            {...props} 
            id={match?.params?.id} 
            campaignErpId={campaignErpId}
            mcfCampaign={mcfCampaign} 
            setMcfCampaign={setMcfCampaign} 
          />}
        />
        <Route
          path={
            generatePath(match.path, {
              id: match?.params?.id,
              tab: "products"
            })
          }
          exact
          render={props => <SalesPriceListEditProducts 
            {...props}
            id={match?.params?.id} 
            campaignErpId={campaignErpId}
            mcfCampaign={mcfCampaign} 
            setMcfCampaign={setMcfCampaign} 
          
          />}
        />
        
      </Switch>

      <Row>
        <Col>
          <div className="d-flex p-2 justify-content-between">
            <Button color="white" onClick={() => history.goBack()}>{t("sales_price_list_edit_basic_cancel","Peruuta")}</Button>
            <Button color="success" onClick={submit}>{t("sales_price_list_edit_basic_save","Tallenna")}</Button>
          </div>
        </Col>
      </Row>

    
    </>
  )
}

export default SalesPriceListEdit;
