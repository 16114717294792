import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { 
  Button, 
  Link, 
  Typography, 
} from "stories/components";
import { useAuthContext } from "contexts/AuthContext"
import { Input, SelectMulti } from "stories/forms";

import {
  httpGetCampaigns,
  httpGetAllMcfCampaigns,
} from "services/campaigns"

const SalesPriceListCreateMcf = ({ history }) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [mcfCampaigns, setMcfCampaigns] = useState([])

  /*
  const getCampaignName = (campaignId) => {
    const campaign = mcfCampaigns.find((campaign) => campaign.id_mcf === campaignId)
    if (!campaign) return ""
    const translation = campaign.translations.find((translation) => translation?.language == myUser?.language)
    console.log("Translation", translation)
    return translation ? translation.name : ""
  }
  */

  const getCampaignName = (campaignId) => {
    const campaign = mcfCampaigns.find((campaign) => campaign.id_mcf === campaignId)
    if (!campaign) return ""
    return campaign[`name_${myUser?.language}`] || ""	
  }
  
  const getMcfCampaigns = () => {
    httpGetAllMcfCampaigns()
    .then((response) => {
      console.log("MCF Campaigns", response.data)
      setMcfCampaigns(response.data)
    })
    .catch((error) => {
      console.log("MCF Campaigns error", error)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getMcfCampaigns()
  }, [])

  const [filters, setFilters] = useState({
    selected_campaign: "",
  })  

  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value,
    }))
  }

  const handleSubmit = () => {
    history.push(`/dashboard/sales_orders/price_list_edit/create/basic/?mcf_campaign=${filters.selected_campaign}`)
  }

  return (
    <>
      <Row>
        <Col className="mb-2">
          <Typography variant="h1">{t("sales_price_list_create_mcf_title","Mihin Mycashflow-kampanjaan myyntihinnasto liitetään?")}</Typography>
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <Input 
            type="select" 
            label={t("sales_price_list_create_mcf_select_campaign","Valitse MCF-kampanja")}
            name="selected_campaign" 
            onChange={handleFilterChange} 
            value={filters?.selected_campaign}
            required
            disabled={loading}
          >
            <option value="">{t("sales_price_list_create_mcf_select_campaign_placeholder","Valitse kampanja")}</option>
            {mcfCampaigns.map((campaign) => (
              <option key={campaign?.id_mcf} value={campaign?.id_mcf}>{getCampaignName(campaign?.id_mcf)}</option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
         <Button color="white">{t("sales_price_list_create_mcf_cancel","Peruuta")}</Button>
        </Col>
        <Col>
         <Button disabled={loading || !filters?.selected_campaign} onClick={handleSubmit}>{t("sales_price_list_create_mcf_submit","Tuo tiedot ja luo hinnasto")}</Button>
        </Col>
        
      </Row>
      
      
    </>
  );
}

export default SalesPriceListCreateMcf;
