import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import {
  AlertModal,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  IconButton,
  Link,
  Typography,
} from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetCustomerUserRoles } from "services/customers"
import { httpInviteUser } from "services/users"
import { useNotification } from "stories/components/Notification"
import { useAppContext } from "contexts/AppContext"

const CustomerUsersList = ({match, history}) => {
  
  const { t } = useTranslation();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [customerId, setCustomerId] = useState()
  const { enums } = useAppContext()
  
  // Table
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })
  
  const getCustomerUsers = (customerId) => {
    setLoading(true)
    httpGetCustomerUserRoles(customerId, filters).then(res => {
      setRows(res?.data)
      setLoading(false)
    })
  }
  
  useEffect(() => {
    if (customerId) {
      getCustomerUsers(customerId)
    }
  }, [customerId, filters])
  
  useEffect(() => {
    if (match && match?.params?.id) {
      setCustomerId(match?.params?.id)
    }
  }, [match])
  
  const getRoleValue = (roleKey) => {
    const roles = enums?.customers?.customer_user_role?.role;
    return roles && roles.reduce((result, role) => {
      if (role.key === roleKey) {
        result = role.value
      }
      return result
    }, "")
  }
  
  /* Invitation BEGIN */
  const [infoAlert, setInfoAlert] = useState();
  
  const handleModalConfirm = (userId) => {
    setInfoAlert(null);
    setLoading(true)
    httpInviteUser(userId).then(res => {
      notify({ title:t("Kutsu"), message:t("Lähetetty onnistuneesti"), type:"default"})
    }, errors => {
      notify({ title:t("Kutsu"), message:t("Lähetys epäonnistui"), type:"error"})
    }).finally(res => {
      setLoading(false)
    })
  }
  const handleModalCancel = () => {
    setInfoAlert(null);
  }
  
  const handleInvite = (userId) => {
    setInfoAlert(
      <AlertModal
        title={t("Lähetä kutsu")}
        onConfirm={() => handleModalConfirm(userId)}
        onCancel={() => handleModalCancel()}
        showCancel={true}
        confirmBtnText={t("Lähetä")}
        cancelBtnText={t("Peruuta")}
      >
        {t("Lähetetäänkö kutsu käyttäjän sähköpostiin?")}
      </AlertModal>
    )
  }
  
  /* Invitation END */
  
  const headers = [
    { label: t("Toiminnot"), key: "actions"},
    { label: t("Nimi"), key: "name"},
    { label: t("Käyttäjätunnus"), key: "username"},
  ];
  
  const handleNew = () => {
    history.push(`/dashboard/customers/users/create/${customerId}/`);
  }
  
  const handleEdit = (id) => {
    history.push(`/dashboard/customers/edit/${id}/basic`);
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map((row, index) => {
      return {
        id: row?.id,
        name: {
          content: 
          (
            <>
              <Typography bold>({index+1}) {row?.user?.full_name}</Typography>
            </>
          ),
          className: "customer_user_name"
        },
        username: (
          <>
            <Typography>{row?.user?.username}</Typography>
            <Typography variant="small">{getRoleValue(row?.role)}</Typography>
          </>
        ),
        actions: (
          <>
            <IconButton size="lg" onClick={() => handleInvite(row?.user?.id)} iconName="envelope" />
            {/*
            <IconButton size="lg" onClick={() => handleEdit(row?.id)} iconName="pen" />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="trash" />
            */}
          </>
        ),
      }
    })
  }, [rows])
  
  return (
    <>
      <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
        <BreadcrumbItem>
          <Link to={`/dashboard/customers/list`}>
            {t("Asiakkaat")}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
        {t("Käyttäjät")}
        </BreadcrumbItem>
      </Breadcrumb>
      
      <Row>
        <Col>
          <PageHeader title={t("Tavarantoimittaja - Käyttäjät")}></PageHeader>
        </Col>
        {/*
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
              <div className="p-2"><Typography variant="small"></Typography></div>
          </div>
        </Col>
        */}
      </Row>
      
      <Row className="d-flex justify-content-between mb-3">
        <Col>
          
        </Col>
        <Col className="text-right">
          <Button color="success" onClick={handleNew}>{t("Lisää käyttäjä")}</Button>
        </Col>
      </Row>
      
      <TableAuto
        color="dark"
        showId={true}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
        tableStickyHeader={true}
      />
      
      {infoAlert}
    </>
  );
}

export default CustomerUsersList;
