import moment from "moment";

export const getDate = (dt) => {
  if (dt) {
    return moment(dt).format('DD.MM.YYYY')
  }
  return "";
}

export const getTime = (dt) => {
  if (dt) {
    return moment(dt).format('HH:mm')
  }
  return "";
}

export const getDateAndTime = (dt) => {
  if (dt) {
    return moment(dt).format('DD.MM.YYYY HH:mm')
  }
  return "";
}