import React, {useEffect, useState, useCallback} from "react"; // useRef, memo
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router'
import moment from "moment";
import { useNotification } from "stories/components/Notification"
import { Container, Row, Col } from "stories/layout";
import { AlertModal, Typography, Button, Link, IconButton, Modal, Spinner, Breadcrumb, BreadcrumbItem } from "stories/components";
import { Input } from "stories/forms";
import { useAuthContext } from "contexts/AuthContext"
import { useAppContext } from "contexts/AppContext"
import { PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetBrands } from "services/brands"
import { httpGetCategories } from "services/categories"
import { httpGetProducts } from "services/products"
import { 
  httpGetPurchaseOrder, 
  httpCreatePurchaseOrder, 
  httpUpdatePurchaseOrder, 
  httpDeletePurchaseOrder, 
  httpGetPurchaseOrderProducts, 
  httpConfirmPurchaseOrder,
  httpGetPurchaseOrderSummary,
} from "services/purchase_orders"
import { httpGetSuppliers } from "services/suppliers"
import { httpGetComplaints } from "services/complaints"
import PurchaseOrderAddProduct from "views/dashboard/PurchaseOrderAddProduct/PurchaseOrderAddProduct"
import PurchaseOrderProductOrder from "views/dashboard/PurchaseOrderProductOrder/PurchaseOrderProductOrder"
import PurchaseOrderPublish from "views/dashboard/PurchaseOrderPublish/PurchaseOrderPublish"
import PurchaseOrderProductDelivery from "views/dashboard/PurchaseOrderProductDelivery/PurchaseOrderProductDelivery"
import TableColumnToggle from "components/TableColumnToggle/TableColumnToggle"
import TextCutter from "components/Helpers/TextCutter"
import { round } from "services/helpers"
import config from "services/config"
import { getDate } from "services/datetime"

const SETTINGS_DEMAND_CUSTOMER_ORDERS = false;
const SETTINGS_DEMAND_COMPLAINTS = true;

const orderDataDefault = {
  name: "",
  product:0,
  product_variation:0,
  batch: 1,
  total_count: 0,
  supplier: undefined,
}

const PurchaseOrderEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     setFieldValue,
     isSubmitting,
     validateForm,
     setTouched,
     setErrors,
     setSubmitting,
   } = props;
   
  const {
      t,
      history,
      orderData,
      setOrderData,
      handleOpenModalPublish,
      notify,
      getOrderProductOrderCount,
      unsavedChanges,
      setUnsavedChanges,
      setOrderId,
  } = props;
  
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingPurchaseOrderProducts, setLoadingPurchaseOrderProducts] = useState(false);
  const [loadingFirstLoaded, setLoadingFirstLoaded] = useState(false);
  const [productsUpdated, setProductsUpdated] = useState(0);
  const { myUser } = useAuthContext()
  const { enums, getTabKeyById, getTabNameById, sidenavOpen } = useAppContext()
  const [rows, setRows] = useState([])
  const [sorting, setSorting] = useState({
    sortingKey: "erp_id_sort",
    sortingReverse: false,
    sortingType: "float",
  })
  
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
   
  const [loadingOrderProduct, setLoadingOrderProduct] = useState({})
  
  const [filters, setFilters] = useState({
    ordering: "-name",
    sales_date_start: moment().subtract(3, 'months').format("YYYY-MM-DD"),
    sales_date_end: moment().format("YYYY-MM-DD"),
  })

  const [dateFilters, setDateFilters] = useState({
    sales_date_start: moment().subtract(3, 'months').format("YYYY-MM-DD"),
    sales_date_end: moment().format("YYYY-MM-DD"),
  })

  const [backendFilters, setBackendFilters] = useState({
    ordering: "-name",
    sales_date_start: moment().subtract(3, 'months').format("YYYY-MM-DD"),
    sales_date_end: moment().format("YYYY-MM-DD"),
  })
  
  const getTabKey = useCallback(() => {
    const state = values?.state;
    return getTabKeyById(state);
  }, [values?.state])
  
  const getTabName = useCallback(() => {
    const state = values?.state;
    return getTabNameById(state);
  }, [values?.state])
  
  const getStatusName = (key) => {
   try {
     return enums && enums?.products?.product?.status.filter(obj => {
       if (obj.key === key) return true;
       return false;
     })[0].value
   }
   catch {
     return ""
   }
   
  }
  
  // Brands
  
  const [brands, setBrands] = useState();
   
   useEffect(() => {
     getBrands();
   }, [])
   
  const getBrands = () => {
    
    httpGetBrands({ordering:"name"}).then(res => {
      setBrands(res?.data)
    })
  }
  
  const getBrandOptions = useCallback(() => {
    return brands && brands.map(brand => {
      return (
        <option key={brand?.id} value={brand?.id}>{brand.name}</option>
      )
    })
  }, [brands])
  
  const getBrandName = useCallback((id) => {
    let brandName = brands && brands.reduce((result, item) => {
      if (parseInt(item.id) === parseInt(id)) { result = item?.name }
      return result
    }, null)
    return brandName ? brandName : ""
  }, [brands])
  
  // Suppliers
  
  const [suppliers, setSuppliers] = useState([])
   
  useEffect(() => {
    getSuppliers()
  }, [])
   
  const getSuppliers = () => {
    const params = {
      active: true,
      ordering: "name",
    }
    httpGetSuppliers(params).then(res => {
      setSuppliers(res.data)
      
      /* we dont want to select automatically
      if (values?.supplier == null && res.data.length > 0) {
        setFieldValue("supplier", res.data[0]?.id)
      }
      */
      
    })
  }
   
  const getSupplierOptions = useCallback(() => {
    return suppliers && suppliers.map(supplier => {
      return (
        <option key={supplier?.id} value={supplier?.id}>{supplier?.name}</option>
      )
    })
  }, [suppliers])
  
  useEffect(() => {
    // Show notification if selected supplier has no users
    
    if (values?.id && values?.id > 0) {
      return;
    }
    
    const supplierId = parseInt(values?.supplier)
    if (supplierId && supplierId > 0) {
      const supplier = suppliers.reduce((result, obj) => {
        if (obj.id === supplierId) {
          result = obj
        }
        return result
      }, null)
      
      if (supplier?.users_count === 0) {
        notify({ 
          title:t("Tavarantoimittaja"),
          message:t("Huom. Valitulla toimittajalla ei käyttäjätunnuksia"),
          type: "warning"
        })
      }
      
      setFieldValue("purchase_order_currency", supplier?.currency)
      
    }
  }, [values?.supplier])
  
  // Categories
  
  const [categories, setCategories] = useState([])
   
  useEffect(() => {
    getCategories()
  }, [])
   
  const getCategories = () => {
    httpGetCategories({ordering:"name"}).then(res => {
      setCategories(res.data)
      if (values?.category == null && res.data.length > 0) {
        setFieldValue("category", res.data[0]?.id)
      }
    })
  }
   
  const getCategoryOptions = useCallback(() => {
    return categories && categories.map(category => {
      return (
        <option key={category?.id} value={category?.id}>{category?.name}</option>
      )
    })
  }, [categories])
  
  // Complaints
  
  const getComplaintsAmount = (_complaints) => {
    if (!_complaints || _complaints.length === 0) {
      return 0;
    }
    let _count = 0;
    _complaints && _complaints.map(complaint => {
      _count += complaint?.amount
      return 0;
    })
    return _count
  }
  
  const getComplaints = () => {
    // Get open complaints for the selected supplier
    
    if (values?.supplier && values?.supplier > 0) {
      const params = {
        supplier: values?.supplier,
        status: 1,
      }
      httpGetComplaints(params).then(res => {
        let _orderData = {
          ...orderData,
        };
        let _complaints = {}
        
        if (res && res?.data && res?.data.length > 0) {
          
          // List all same product complaints under same dict key
          res.data && res.data.map(complaint => {
            const productId = complaint?.product;
            const productVariationId = complaint?.product_variation ? complaint?.product_variation : 0;
            const orderProductKey = `p${productId}v${productVariationId}`;
            if (_complaints[orderProductKey] !== undefined) {
              _complaints[orderProductKey].push(complaint)
            }
            else {
              _complaints[orderProductKey] = [complaint]
            }
            return 0;
          })
        }
        
        // Option: Automatically select all product complaints as part of the order
        if (config?.purchase_order_complaints_auto_add === true) {
          for (const [key, value] of Object.entries(_orderData)) {
            if (_complaints[key] !== undefined) {
              _orderData[key].complaints = _complaints[key]
              _orderData[key].complaints_count = getComplaintsAmount(_complaints[key])
            }
          }
        }
        
        setOrderData(_orderData)

      })
    }
  }
  
  useEffect(() => {
    getProducts()
  }, [values?.supplier, backendFilters])
  
  const getProductsInStock = (product) => {
    if (product?.stock_item?.quantity && product?.stock_item?.quantity > 0) {
      return product?.stock_item?.quantity
    }
    else if (product?.stock_item && product?.stock_item?.quantity === 0) {
      return 0
    }
    return null;
  }

  const getVariationsInStock = (product) => {
    let max_stock = 0;
    if (product.variations && product.variations.length > 0) {
      product.variations.map(variation => {
        const productsInStockCount = getProductsInStock(variation);
        if (productsInStockCount && productsInStockCount > max_stock && variation?.stock_item?.enabled === true) {
          max_stock = productsInStockCount;
        }
      })
    }
    return max_stock
  }
  
  const getProductSupplierCodesCountRoot = (row) => {
    let _count = 0
    row?.product_supplier_codes && row.product_supplier_codes.map(product_supplier_code => {
      if (parseInt(product_supplier_code?.supplier_fk) === parseInt(values?.supplier)) {
        _count += 1
      }
    })
    return _count
  }
  
  const getProducts = () => {
    if (values?.supplier) {
      const params = {
        ...backendFilters,
        supplier: values?.supplier,
        purchase_order_only: values?.id && values?.state > 1 ? values?.id : undefined,
        purchase_order: values?.id ? values?.id : undefined,
      }
      setLoadingProducts(true);
      httpGetProducts(params).then(res => {
        let _rows = [];
        let order_data = {};
        res.data && res.data.map(product => {
          
          const hasVariations = product.variations && product.variations.length > 0 ? true : false;
          const demandInt = product?.demand ? parseInt(product?.demand) : 0;
          const orderProductKey = `p${product?.id}v0`;
          
          let productData = {
            ...product,
            demand: demandInt,
            orderProductKey: orderProductKey,
          }
          
          const filter_stock_balance_max = parseInt(filters?.stock_balance_max)
          
          if (filter_stock_balance_max >= 0) // Filter: Stock balance is on
          {
            if (hasVariations === false) {
              const productsInStockCount = getProductsInStock(product)
              if (productsInStockCount && productsInStockCount <= filter_stock_balance_max) {
                productData.has_variations = hasVariations;
                _rows.push({
                  ...productData
                })
              }
            }
            else {
              const variationsInStockCount = getVariationsInStock(product)
              if (variationsInStockCount && variationsInStockCount <= filter_stock_balance_max) {
                productData.has_variations = hasVariations;
                _rows.push({
                  ...productData
                })
              }
            }
          }
          else {
            productData.has_variations = hasVariations;
            _rows.push({
              ...productData
            })
          }
          
          // Initialize OrderProduct
          
          const product_purchase_price = productData?.purchase_price ? parseFloat(productData?.purchase_price) : 0;
          // const price = productData?.price ? parseFloat(productData?.price) : 0;
          
          if (orderData.hasOwnProperty(orderProductKey)) {
            order_data[orderProductKey] = {
              ...orderDataDefault,
              ...orderData[orderProductKey],
              name: product?.name,
              product:product?.id,
              product_variation:0,
              hasVariations: hasVariations,
              previous_price: product_purchase_price,
            }
          }
          else {
            order_data[orderProductKey] = {
              ...orderDataDefault,
              name: product?.name,
              product:product?.id,
              product_variation:0,
              hasVariations: hasVariations,
              previous_price: product_purchase_price,
            }
          }
          
          // By default select supplier code if there is just one
          if (getProductSupplierCodesCountRoot(product) === 1) {
            
            product?.product_supplier_codes.map(product_supplier_code => {
              if (parseInt(product_supplier_code?.supplier_fk) === parseInt(values?.supplier)) {
                order_data[orderProductKey].product_supplier_code_id = product_supplier_code?.id
                order_data[orderProductKey].batch = product_supplier_code?.batch
                order_data[orderProductKey].minimum_order_quantity = product_supplier_code?.minimum_order_quantity
                order_data[orderProductKey].demand = demandInt
                order_data[orderProductKey].supplier_code_purchase_order_product_price_previous = product_supplier_code?.purchase_order_product_price_previous
              }
            })
          }
          
          if (hasVariations) {
            product.variations.map(variation => {
              const demandIntVariation = variation?.demand ? parseInt(variation?.demand) : 0;
              const orderProductKey = `p${product?.id}v${variation?.id}`;
              
              let variationData = {
                ...variation,
                hasVariations: false,
                demand: demandIntVariation,
                orderProductKey: orderProductKey,
              }
              
              if (variation.stock_item && variation.stock_item.enabled === true) {
                if (filter_stock_balance_max >= 0) {
                  const productsInStockCount = getProductsInStock(variation)
                  if (productsInStockCount && productsInStockCount <= filter_stock_balance_max) {
                    _rows.push({
                      ...variationData,
                    });
                  }
                }
                else {
                  _rows.push({
                    ...variationData,
                  });
                }
              }
              // Disabled to show on demand variations
              //else if (variation.stock_item && variation.stock_item.enabled === false) {
                // pass
              //}
              else {
                _rows.push({
                  ...variationData,
                });
              }
              
              // Initialize OrderProduct
              
              const variation_purchase_price = variation?.purchase_price ? parseFloat(variation?.purchase_price) : 0;
              // const variation_price = variation?.price ? parseFloat(variation?.price) : 0;
              
              if (orderData.hasOwnProperty(orderProductKey)) {
                order_data[orderProductKey] = {
                  ...orderDataDefault,
                  ...orderData[orderProductKey],
                  name: product?.name + " " + variation?.name,
                  product:product?.id,
                  product_variation:variation.id,
                  hasVariations: false,
                  previous_price: variation_purchase_price,
                }
              }
              else {
                order_data[orderProductKey] = {
                  ...orderDataDefault,
                  name: product?.name + " " + variation?.name,
                  product:product?.id,
                  product_variation:variation.id,
                  hasVariations: false,
                  previous_price: variation_purchase_price,
                }
              }
              
              
              
              // By default select supplier code if there is just one
              if (getProductSupplierCodesCountRoot(variation) === 1) {
                
                variation?.product_supplier_codes.map(product_supplier_code => {
                  if (parseInt(product_supplier_code?.supplier_fk) === parseInt(values?.supplier)) {
                    order_data[orderProductKey].product_supplier_code_id = product_supplier_code?.id
                    order_data[orderProductKey].batch = product_supplier_code?.batch
                    order_data[orderProductKey].minimum_order_quantity = product_supplier_code?.minimum_order_quantity
                    order_data[orderProductKey].demand = demandIntVariation
                    order_data[orderProductKey].supplier_code_purchase_order_product_price_previous = product_supplier_code?.purchase_order_product_price_previous
                  }
                })
              }
              
            })
            
          }
          
        })
        
        setOrderData(order_data);
        setRows(_rows);
        setProductsUpdated(s => s+1);
        
      }).finally(() => {
        setLoadingProducts(false);
        setLoadingFirstLoaded(true);
      })
    }
  }
  
  useEffect(() => {
    if (!values?.id) {
      setOrderData({})
    }
  }, [values?.supplier])
  
  /* Create or update product in orderData state */
  const updateOrderProduct = (productId, productVariationId, newOrderProductData) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    let obj = orderData[orderProductKey];
    if (!obj) {
      obj = {}
    
    
      obj = {
        ...obj,
        product: productId,
        product_variation: productVariationId,
        ...newOrderProductData,
      }
      
      setOrderData(s => ({
        ...s,
        [orderProductKey]: obj
      }))
    }
    else {
      setOrderData(s => ({
        ...s,
        [orderProductKey]: {
          ...s[orderProductKey],
          product: productId,
          product_variation: productVariationId,
          ...newOrderProductData,
        }
      }))
    }
  
  }
  
  /* Update one field of product in orderData */
  const updateOrderProductField = (productId, productVariationId, key, value) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    if (orderData.hasOwnProperty(orderProductKey)) {
      const orderProduct = {
        ...orderData[orderProductKey],
        [key]:value
      }
      
      setOrderData(s => ({
        ...s,
        [orderProductKey]: orderProduct
      }))
      
    }
    
  }
  
  const handleOrderProductChange = ({target}, productId, productVariationId) => {
    setUnsavedChanges(true)
    updateOrderProductField(productId, productVariationId, target.name, target.value)
  }
  
  const handleInputFloatBlur = ({target}, productId, productVariationId) => {
    let value = 0.0;
    if (target.value) {
      value = target.value.toString();
      value = value.replace(",", ".");
      value = parseFloat(value);
    }
    // updateOrderProductField(productId, productVariationId, target.name, parseFloat(target.value).toString())
    updateOrderProductField(productId, productVariationId, target.name, value)
  }
  
  const getOrderProduct = useCallback((productId, productVariationId) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    return orderData[orderProductKey]
  }, [orderData])
  
  const getOrderProductValue = (field, productId, productVariationId) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    let obj = orderData[orderProductKey];
    if (obj) {
      return obj[field];
    }
    return undefined;
  }
  
  useEffect(() => {
    if (productsUpdated > 0) {
      if (values?.id) {
        getPurchaseOrderProducts()
      }
      else {
        getComplaints()
      }
    }
  }, [productsUpdated])
  
  const getRowsProduct = (orderProductKey) => {
    // Get Product or ProductVariation from rows with orderProductKey
    return rows && rows.find(object => object.orderProductKey === orderProductKey);
  }
  
  const getProductSupplierCode = (id, orderProductKey) => {
    const product = getRowsProduct(orderProductKey)
    if (product) {
      return product?.product_supplier_codes.find(object => object.id === id);
    }
    return null;
  }    
  
  const getPurchaseOrderProducts = () => {

    let params = {
      amount__gte: values?.state > 1 ? 1 : undefined,
    }
    
    setLoadingPurchaseOrderProducts(true)
    if (values?.id) {
      
      httpGetPurchaseOrderProducts(values?.id, params).then(res => {
        
        let order_data = {};
        
        res?.data && res?.data.map(po_product => {
          const orderProductKey = `p${po_product?.product?.id}v${po_product?.product_variation ? po_product?.product_variation?.id : "0"}`;
          
          const purchase_price_weight = po_product?.new_purchase_price ? po_product?.new_purchase_price : null
          const previous_sell_price = po_product?.previous_sell_price ? po_product?.previous_sell_price : (po_product?.product_variation ? po_product?.product_variation?.price : po_product?.product?.price)
          const sell_price = po_product?.new_sell_price ? po_product?.new_sell_price : previous_sell_price;
          
          // Add previously selected product supplier code data
          let product_supplier_code_data = {}
          if (po_product?.product_supplier_code_id && po_product?.product_supplier_code_id > 0) {
            const productSupplierCode = getProductSupplierCode(po_product?.product_supplier_code_id, orderProductKey)
            
            if (productSupplierCode) {
              product_supplier_code_data = {
                batch: productSupplierCode?.batch,
                minimum_order_quantity: productSupplierCode?.minimum_order_quantity,
              }
            }

          }
          
          if (orderData.hasOwnProperty(orderProductKey)) {
            order_data[orderProductKey] = {
              ...po_product,
              product:po_product?.product?.id,
              product_variation:po_product?.product_variation ? po_product?.product_variation.id : 0,
              name: po_product?.product_variation ? po_product?.product_variation?.name : po_product?.product?.name,
              purchase_price_weight: purchase_price_weight,
              sell_price: sell_price,
              complaints_amount_total_original: po_product?.complaints_amount_total, // for badge calc
              customer_orders_amount_total_original: po_product?.customer_orders_amount_total, // for badge calc
              complaints_list_length: po_product?.complaints.length, // for badge calc
              complaints_list_length_original: po_product?.complaints.length, // for badge calc
              customer_orders_list_length: po_product?.customer_orders.length, // for badge calc
              customer_orders_list_length_original: po_product?.customer_orders.length, // for badge calc
              ...orderData[orderProductKey], // last, so that value changes in frontend remains
              ...product_supplier_code_data,
            }
          }
          else {
            order_data[orderProductKey] = {
              ...po_product,
              product:po_product?.product?.id,
              product_variation:po_product?.product_variation ? po_product?.product_variation.id : 0,
              name: po_product?.product_variation ? po_product?.product_variation?.name : po_product?.product?.name,
              purchase_price_weight: purchase_price_weight,
              sell_price: sell_price,
              complaints_amount_total_original: po_product?.complaints_amount_total, // for badge calc
              customer_orders_amount_total_original: po_product?.customer_orders_amount_total, // for badge calc
              complaints_list_length: po_product?.complaints.length, // for badge calc
              complaints_list_length_original: po_product?.complaints.length, // for badge calc
              customer_orders_list_length: po_product?.customer_orders.length, // for badge calc
              customer_orders_list_length_original: po_product?.customer_orders.length, // for badge calc
              ...product_supplier_code_data,
            }
          }
          
        })
        
        setOrderData(s => ({
          ...s,
          ...order_data,
        }));
        
      }, error => {
        notify({ 
          title:t("Tilaustuotteet"),
          message:t("Tuotteiden haku epäonnistui"),
          type: "danger"
        })
      }).finally(() => {
        setLoadingPurchaseOrderProducts(false)
      })
    }
  }
  
  // Modal: Add Product order amounts
  
  const [selectedPurchaseOrderProduct, setSelectedPurchaseOrderProduct] = useState()
  const [modalAddProductOrderOpen, setModalAddProductOrderOpen] = useState(false)
  
  const toggleAddProductOrder = () => {
    setModalAddProductOrderOpen(s => !s);
  }
  
  const resolveAddProductOrder = () => {
    setSelectedPurchaseOrderProduct(null)
  }

  const rejectAddProductOrder = () => {
    setSelectedPurchaseOrderProduct(null)
  }
  
  const handleOpenProductOrder = (productId, productVariationId) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    const obj = orderData[orderProductKey];
    setSelectedPurchaseOrderProduct(obj)
  }

  useEffect(() => {
    if (selectedPurchaseOrderProduct) {
      setModalAddProductOrderOpen(true)
    }
    else {
      setModalAddProductOrderOpen(false)
    }
  }, [selectedPurchaseOrderProduct])
  
  const [headers, setHeaders] = useState([
    // { label: t("SortValue"), key: "sortValue", sortingKey: "sortValue", sortable:false, visible: true},
    { label: t("Tila"), labelSlice: false, key: "status", sortingKey: "status_sort", sortable: true, sortingType: "int", visible: true},
    { label: t("ERP ID"), key: "id", sortingKey: "erp_id_sort", sortable: true, sortingType: "float", visible: true},
    { label: t("MCF Päätuotteen ID"), key: "mcf_product_id", visible: true},
    { label: t("MCF variaatio tuotteen ID"), key: "mcf_variation_id", visible: true},
    { label: t("Tuotemerkki"), key: "brand_id", sortingKey: "brand_id_sort", sortable: true, sortingType: "string", visible: true},
    { label: t("Nimi"), key: "name", sortingKey: "name_sort", sortable: true, sortingType: "string", visible: true, className: `table_sticky_th_column ${sidenavOpen ? "table_sticky_th_column_left_250" : ""}`},
    { label: t("Paino"), key: "weight", sortingKey: "weight_sort", sortable: true, sortingType: "float", visible: true},
    { label: t("Varastopaikka"), key: "stock_location", visible: true},
    { label: t("Viivakoodi"), key: "barcode", visible: true},
    { label: t("Tuotenumero"), key: "code", visible: true},
    { label: t("Toimittajan tuo.nro"), key: "codes", sortingKey: "codes_sort", sortable: true, sortingType: "string", visible: true},
    { label: t("Batch"), key: "batch", visible: true},
    { label: t("Minimitilausmäärä"), key: "minimum_order_quantity", visible: true},
    { label: t("Hälytysraja"), key: "stock_item_balance_limit", visible: true},
    { label: t("Ostohinta toimittajalla"), key: "buy_price_previous", visible: true}, // Ostohinta toimittajalla on viimeisimmän vahvistetun tilauksen Ostohinta tilauksella samalta toimittajalta ja luonnollisesti samalle toimittajan tuotenumerolle. EI MUOKATTAVISSA. Näkyy vain ostajalle. Valuutta toimittajan valuutan mukaan tai tarvittaessa muunnettu kurssilla.
    { label: t("Ostohinta tilauksella"), key: "buy_price", visible: true}, // Ostohinta tilauksella on duplikaatti Ostohinta toimittajalla -sarakkeen arvoista valitulla toimittajalla ja tuotenumerolla. ARVO ON MUOKATTAVISSA ostajan ja toimittajan välisen keskustelun mukaisesti. Näkyy sekä ostajalle että toimittajalle. Valuutta toimittajan valuutan mukaan tai tarvittaessa muunnettu kurssilla.
    { label: t("Ostohinta tilaajalla (edel)(painotet)"), key: "purchase_price_weight_old", visible: true}, // Edellinen vahvistettu painotettu ostohinta tilaajalla, tulee suoraan tuotteen takaa tai jos tilaus edennyt pidemmälle käytetään tilaustuotteelle tallennettua ostohintaa
    { label: t("Ostohinta tilaajalla (painotet)"), key: "purchase_price_weight", visible: true}, // Painotettu ostohinta ostajalla on ostohintalaskurissa laskettu ostohinta, jossa on huomioitu tilauksen lisäkulut (rahti, tulli ym.). EI MUOKATTAVISSA, mutta tulee syötteenä tilauksen ostohintalaskurista, kun uudet arvot tallennetaan. Näkyy vain ostajalle. Valuutta aina EURO.
    { label: t("Myyntihinta"), key: "sell_price", visible: true},
    { label: t("Kate-%"), key: "sales_margin_pct", sortingKey: "sales_margin_pct_sort", sortable: true, sortingType: "float", visible: true},
    { label: t("purchase_order_product_vat","ALV%"), key: "vat_rate", visible: true},
    { label: t("Varastossa"), key: "stock", visible: true},
    { label: t("Varattuna"), key: "reserved", visible: true},
    { label: t("Varastossa vapaana"), key: "stock_free", sortingKey: "stock_free_sort", sortable: true, sortingType: "int", visible: true},
    { label: t("purchase_order_edit_header_previous_order_count","Aiemmin tilatut yhteensä"), key: "previous_order_count", sortingKey: "previous_order_count_sort", sortable: true, sortingType: "int", visible: true},
    { label: t("purchase_order_edit_header_customer_orders","Ennakkotilaukset"), key: "customer_orders", visible: true},
    { label: t("Reklamaatiot"), key: "complaints", sortingKey: "complaints_sort", sortable: true, sortingType: "int", visible: true},
    { label: t("Tilattava määrä (valitulla tuotekoodilla)"), key: "order_amount", sortingKey: "order_amount_sort", sortable: true, visible: true},
    { label: t("Tarve (valitulla tuotekoodilla)"), key: "demand_supplier_code", sortingKey: "demand_supplier_code_sort", sortable: true, sortingType: "int", visible: true},
    { label: t("Tilattava määrä (yks)"), key: "order_amount_single", sortingKey: "order_amount_single_sort", sortable: true, visible: true},
    { label: t("Tarve (yks)"), key: "demand", sortingKey: "demand_sort", sortable: true, sortingType: "int", visible: true},
    { label: t("Saapuva määrä yhteensä"), key: "order_total_count", sortingKey: "order_total_count_sort", sortable: true, sortingType: "int", visible: true},
    { label: t("Tilattavan arvo yhteensä"), key: "order_total_value", visible: true},
    { label: t("Myynti"), key: "sales_manual", sortingKey: "sales_manual_sort", sortable: false, visible: true},
    { label: t("Myynti 1kk"), key: "sales_1m", sortingKey: "sales_1m_sort", sortable: true, visible: true},
    { label: t("Myynti 3kk"), key: "sales_3m", sortingKey: "sales_3m_sort", sortable: true, visible: true},
    { label: t("Myynti 6kk"), key: "sales_6m", sortingKey: "sales_6m_sort", sortable: true, visible: true},
    { label: t("Myynti 9kk"), key: "sales_9m", sortingKey: "sales_9m_sort", sortable: true, visible: true},
    { label: t("Myynti 1v"), key: "sales_1y", sortingKey: "sales_1y_sort", sortable: true, visible: true},
    { label: t("Myynti 2v"), key: "sales_2y", sortingKey: "sales_2y_sort", sortable: true, visible: true},
    { label: t("Myynti 3v"), key: "sales_3y", sortingKey: "sales_3y_sort", sortable: true, visible: true},
    { label: t("Myynti 5v"), key: "sales_5y", sortingKey: "sales_5y_sort", sortable: true, visible: true},
  ])
  
  const setHeaderValue = (headerKey, key, value) => {
    let headersTemp = headers;
    
    const index = headersTemp && headersTemp.findIndex(item => { return item?.key === headerKey });
    headersTemp[index] = {
      ...headersTemp[index],
      [key]: value,
    }
    setHeaders([
      ...headersTemp
    ])
  }
  
  useEffect(() => {
    setHeaderValue("name","className", `table_sticky_th_column ${sidenavOpen ? "table_sticky_th_column_left_250" : ""}`);
  }, [sidenavOpen])
  
  useEffect(() => {
    if (dateFilters?.sales_date_start && dateFilters?.sales_date_end) {
      const sales_date_start = dateFilters?.sales_date_start ? getDate(dateFilters?.sales_date_start) : "";
      const sales_date_end = dateFilters?.sales_date_end ? getDate(dateFilters?.sales_date_end) : "";
      setHeaderValue("sales_manual","label", `${t("Myynti")} ${sales_date_start} - ${sales_date_end}`);
    }
  }, [dateFilters?.sales_date_start, dateFilters?.sales_date_end])
  
  const getSecondHeader = () => {
    // SUMs header
    
    return {
      demand_count: { content: orderDemandCount, key: "demand_count"}, // need / demand / tarve
      order_amount: { content: orderProductsCount, key: "order_amount"},
      demand_supplier_code: { content: <Button onClick={handleCopyDemandToOrderAmount} size="sm" variant="secondary">{t("purchase_order_edit_second_header_move_demand_to_order","Siirrä tarve tilattavaksi")}</Button>, key: "demand_supplier_code"},
    }
  }
  
  const getStockBalanceColor = (stock_item) => {
    if (stock_item) {
      if ((stock_item?.quantity && stock_item?.balance_limit && stock_item?.quantity <= stock_item?.balance_limit) || (stock_item?.quantity === 0)) {
        return "text-danger"
      }
      else {
        return "text-success"
      }
    }
    return ""
  }
  
  const getCustomerOrdersCount = (productRow) => {
    if (productRow?.has_variations) {
      return null;
    }
    const productId = productRow?.extra?.is_variation ? productRow?.product_fk?.id : productRow?.id;
    const productVariationId = productRow?.extra?.is_variation ? productRow?.id : 0;
    const orderProduct = getOrderProduct(productId, productVariationId);
    
    if (!orderProduct) {
      return null;
    }
    
    const count = orderProduct?.customer_orders_amount_total ? parseInt(orderProduct?.customer_orders_amount_total) : 0
    return count;
  }
  
  const getComplaintsCount = (productRow) => {
    if (productRow?.has_variations) {
      return null;
    }
    const productId = productRow?.extra?.is_variation ? productRow?.product_fk?.id : productRow?.id;
    const productVariationId = productRow?.extra?.is_variation ? productRow?.id : 0;
    const orderProduct = getOrderProduct(productId, productVariationId);
    
    if (!orderProduct) {
      return null;
    }
    const count = orderProduct?.complaints_amount_total ? parseInt(orderProduct?.complaints_amount_total) : 0;
    return count;
  }
  
  const handleCopyDemandToOrderAmount = () => {
    rows.map(row => {
      const productId = row?.extra?.is_variation ? row?.product_fk?.id : row?.id;
      const productVariationId = row?.extra?.is_variation ? row?.id : 0;
      
      let customerOrdersCount = 0
      if (SETTINGS_DEMAND_CUSTOMER_ORDERS === true) {
        customerOrdersCount = getCustomerOrdersCount(row);
      }
      let complaintsCount = 0
      if (SETTINGS_DEMAND_COMPLAINTS) {
        complaintsCount = getComplaintsCount(row);
      }
      
      const rowBatch = getOrderProductValue("batch", productId, productVariationId);
      let rowDemand = row?.demand;
      if (customerOrdersCount > 0) {
        rowDemand += customerOrdersCount * rowBatch;
      }
      if (complaintsCount > 0) {
        rowDemand -= complaintsCount * rowBatch;
      }
      
      let rowDemandSupplierCode = 0;
      if (rowDemand && rowDemand > 0 && rowBatch && rowBatch > 0) {
        rowDemandSupplierCode = round(parseFloat(rowDemand)/parseFloat(rowBatch), 2);
      }
      // Round a float up to the next integer
      rowDemandSupplierCode = Math.ceil(rowDemandSupplierCode);
      updateOrderProductField(productId, productVariationId, "amount", rowDemandSupplierCode);
    })
  }
  
  const getTableRows = useCallback(() => {
    
    const sortingType = sorting?.sortingType;
    const sortingKey = sorting?.sortingKey;
    const sortingReverse = sorting?.sortingReverse; // (true/false)
    
    const getCustomerOrders = (productRow, rowBatch) => {
      
      if (productRow?.has_variations) {
        return undefined;
      }
      const productId = productRow?.extra?.is_variation ? productRow?.product_fk?.id : productRow?.id;
      const productVariationId = productRow?.extra?.is_variation ? productRow?.id : 0;
      let count = getCustomerOrdersCount(productRow)
      if (!count) {
        count = 0
      }
      let rowBatchLocal = rowBatch
      if (!rowBatchLocal) {
        rowBatchLocal = 0
      }
      
      const customer_orders_list_length_original = getOrderProductValue("customer_orders_list_length_original", productId, productVariationId);
      const customer_orders_list_length = getOrderProductValue("customer_orders_list_length", productId, productVariationId);
      
      let badgeValue = 0
      try {
        badgeValue = (productRow?.customer_orders_open_count ? productRow?.customer_orders_open_count : 0) + (customer_orders_list_length_original ? customer_orders_list_length_original : 0) - (customer_orders_list_length ? customer_orders_list_length : 0);
      } catch {
        badgeValue = 0;
      }
      
      return (
          <div className="d-flex align-items-between">
            <div className="pr-2" title="count(count*batch)">{count}&nbsp;({count*rowBatchLocal})</div>
            <IconButton 
              color="default" 
              size="lg" 
              onClick={() => handleOpenProductOrder(productId, productVariationId)} 
              iconName="pen" 
              badgeColor="warning" 
              badgeValue={badgeValue ? badgeValue : null} 
            />
          </div>
        )   
    }
    
    const getProductComplaints = (productRow, rowBatch) => {
      if (productRow?.has_variations) {
        return undefined;
      }
      const productId = productRow?.extra?.is_variation ? productRow?.product_fk?.id : productRow?.id;
      const productVariationId = productRow?.extra?.is_variation ? productRow?.id : 0;
      
      let count = getComplaintsCount(productRow)
      if (count == null) {
        count = 0
      }
      let rowBatchLocal = rowBatch
      if (!rowBatchLocal) {
        rowBatchLocal = 0
      }
      const complaints_list_length_original = getOrderProductValue("complaints_list_length_original", productId, productVariationId);
      const complaints_list_length = getOrderProductValue("complaints_list_length", productId, productVariationId);
      
      let badgeValue = 0;
      try {
        badgeValue = productRow?.complaints_open_count + (complaints_list_length_original ? complaints_list_length_original : 0) - (complaints_list_length ? complaints_list_length : 0);
      } catch(e) {
        badgeValue = 0;
      }
      
      return (
          <div className="d-flex align-items-between" style={{width: "100%"}}>
            <div className="pr-2" title="count(count*batch)">{count}&nbsp;({count*rowBatchLocal})</div>
            <div>
              <IconButton 
                color="default" 
                size="lg" 
                onClick={() => handleOpenProductOrder(productId, productVariationId)} 
                iconName="pen" 
                badgeColor="warning" 
                badgeValue={badgeValue ? badgeValue : null} 
              />
            </div>
          </div>
        )
      
    }
    
    const getProductSupplierCodesCount = (row) => {
      let _count = 0
      row?.product_supplier_codes && row.product_supplier_codes.map(product_supplier_code => {
        if (parseInt(product_supplier_code?.supplier_fk) === parseInt(values?.supplier)) {
          _count += 1
        }
      })
      return _count
    }
    
    const getProductSupplierCodeInputOptions = (row, lockedCodeId) => {
      // @lockedCodeId: Limits the choices if complaint or customer order is selected
      return row?.product_supplier_codes && row.product_supplier_codes.map(product_supplier_code => {
        if (parseInt(product_supplier_code?.supplier_fk) === parseInt(values?.supplier)) {
          if (!lockedCodeId || lockedCodeId === product_supplier_code.id) {
            return (
              <option value={product_supplier_code.id}>{product_supplier_code.name}</option>
            )
          }
        }
      })
    }
    
    const handleProductSupplierCodeChange = ({target}, product_supplier_codes, productId, productVariationId) => {
      let product_supplier_code = product_supplier_codes.reduce((result, item) => {
        if (parseInt(item.id) === parseInt(target.value)) { result = item }
        return result
      }, null)
      
      updateOrderProduct(productId, productVariationId, {
        product_supplier_code_id: product_supplier_code?.id,
        batch: product_supplier_code?.batch,
        minimum_order_quantity: product_supplier_code?.minimum_order_quantity,
        supplier_code_purchase_order_product_price_previous: product_supplier_code?.purchase_order_product_price_previous
      })
      
    }
    
    let rowsDict = {};
    
    rows && rows.map((row, i) => {
      
      const productId = row?.extra?.is_variation ? row?.product_fk?.id : row?.id;
      const productVariationId = row?.extra?.is_variation ? row?.id : 0;
      
      const getOrderProductFieldValue = (fieldName) => {
        return getOrderProductValue(fieldName, productId, productVariationId);
      }
      
      // Tilattu määrä (tuotekoodilla)
      let orderProductAmount = getOrderProductFieldValue("amount");
      if (orderProductAmount && orderProductAmount > 0) {
        orderProductAmount = parseInt(orderProductAmount);
      }
      else {
        orderProductAmount = 0
      }
      
      const getProductSupplierCodeName = () => {
        let productSupplierCodeId = getOrderProductFieldValue("product_supplier_code_id");
        if (productSupplierCodeId && productSupplierCodeId > -1) {
          productSupplierCodeId = parseInt(productSupplierCodeId);
          const i = row?.product_supplier_codes.findIndex(obj => obj.id === productSupplierCodeId);
          if (i > -1) {
            return row?.product_supplier_codes[i].name;
          }
        }
        return "";
      }
      
      const getOrderAmountSort = () => {
        if (!row?.has_variations) {
          if (orderProductAmount && orderProductAmount > 0) {
            return orderProductAmount;
          }
        }
        return 0;
      }
      
      const rowBatch = getOrderProductFieldValue("batch");
      
      // Ennakkotilaukset (tuotekoodilla)
      const customerOrdersCount = getCustomerOrdersCount(row); 
      // Ennakkotilaukset (yks)
      const customerOrdersCountUnits = customerOrdersCount ? customerOrdersCount * rowBatch : 0;
      // Reklamaatiot (tuotekoodilla)
      const complaintsCount = getComplaintsCount(row); 
      // Reklamaatiot (yks)
      const complaintsCountUnits = complaintsCount ? complaintsCount * rowBatch : 0;
      
      let purchase_price_weight_old = getOrderProductFieldValue("purchase_price_weight_old")
      if (!purchase_price_weight_old) {
        purchase_price_weight_old = row?.purchase_price
      }
      const purchase_price_weight = getOrderProductFieldValue("purchase_price_weight");
      const sell_price = row?.price ? parseFloat(row?.price) : 0.0;
      
      // Supplier product code lock (by selected Complaints or Customer orders)
      let productSupplierCodeLock = null;
      const product_supplier_code_id = parseInt(getOrderProductFieldValue("product_supplier_code_id"));
      if (product_supplier_code_id > 0 && (complaintsCount > 0 || customerOrdersCount > 0)) {
        productSupplierCodeLock = product_supplier_code_id;
      }
      
      // Order total count - Saapuva määrä yhteensä
      const order_total_count = customerOrdersCount + complaintsCount + orderProductAmount;
      const orderTotalCountSort = !row?.has_variations ? order_total_count : -1; // OLD:getTotalCount(row)
      
      const vat_rate = row?.vat_rate ? parseFloat(row?.vat_rate) : 0.0;
      let sales_margin_pct = null;
      // Show sales margin formula in UI (Kate-% kaava näkyviin)
      let sales_margin_pct_expression = "";
      
      if (purchase_price_weight && sell_price) {
        sales_margin_pct = (sell_price / (1.0 + vat_rate / 100.0) - purchase_price_weight) / (sell_price / (1.0 + vat_rate / 100.0)) * 100.0
        sales_margin_pct = round(sales_margin_pct, 2);
        sales_margin_pct_expression = `(${sell_price} / (1.0 + ${vat_rate} / 100.0) - ${purchase_price_weight}) / (${sell_price} / (1.0 + ${vat_rate} / 100.0)) * 100.0`
      }
      else if (purchase_price_weight_old && sell_price) {
        sales_margin_pct = (sell_price / (1.0 + vat_rate / 100.0) - purchase_price_weight_old) / (sell_price / (1.0 + vat_rate / 100.0)) * 100.0
        sales_margin_pct = round(sales_margin_pct, 2);
        sales_margin_pct_expression = `(${sell_price} / (1.0 + ${vat_rate} / 100.0) - ${purchase_price_weight_old}) / (${sell_price} / (1.0 + ${vat_rate} / 100.0)) * 100.0`
      }
      
      // Tarve (yks)
      let rowDemand = row?.demand; 
      if (SETTINGS_DEMAND_CUSTOMER_ORDERS === true && customerOrdersCount > 0) {
        rowDemand += customerOrdersCountUnits;
      }
      if (SETTINGS_DEMAND_COMPLAINTS === true && complaintsCount > 0) {
        rowDemand -= complaintsCountUnits;
      }
      
      let rowDemandSupplierCode = 0;
      let rowDemandSupplierCodeSort = 0;
      if (rowDemand && rowDemand > 0 && rowBatch && rowBatch > 0) {
        rowDemandSupplierCode = round(parseFloat(rowDemand)/parseFloat(rowBatch), 2);
        rowDemandSupplierCodeSort = rowDemandSupplierCode;
      }
      
      // Tilattu määrä (yks)
      const order_amount_single = orderProductAmount * rowBatch;
      
      let orderTotalCountClassName = "";
      
      // Color code: Order total count / Saapuva määrä yhteensä
      if (rowDemand === 0 && order_total_count === 0) {
        // Valkoinen = "Tarve (yks)" ja "Saapuva määrä yhteensä" molemmat 0
      }
      else if (rowDemand > (order_amount_single + complaintsCountUnits)) {
        // Punainen = "Tarve (yks)" > "Tilattava määrä (yks)" + "Reklamaatiot (yks)"
        orderTotalCountClassName = "lightRedBackground";
      }
      else if ((rowDemand <= (order_amount_single + complaintsCountUnits)) || (rowDemand === 0 && customerOrdersCount > 0)) {
        // Vihreä = "Tarve (yks)" <= "Tilattava määrä (yks)" + "Reklamaatiot (yks)" Vihreä = "Tarve (yks)" = 0 ja valitut "Ennakkotilaukset" > 0
        orderTotalCountClassName = "lightGreenBackground";
      }
      
      // buy_price_previous
      const buy_price_previous = getOrderProductFieldValue("supplier_code_purchase_order_product_price_previous")
      
      // buy_price
      // let buy_price = getOrderProductFieldValue("price");
      // buy_price = buy_price ? parseFloat(buy_price) : 0.0;
      let orderProductPriceInputValue = getOrderProductFieldValue("price");
      let orderProductPriceFloat = getOrderProductFieldValue("price");

      if (orderProductPriceFloat && orderProductPriceFloat >= 0) {
        orderProductPriceFloat = parseFloat(orderProductPriceFloat);
      }
      else if (buy_price_previous && buy_price_previous > 0) {
        // Copy value
        orderProductPriceFloat = parseFloat(buy_price_previous);
      }
      else {
        orderProductPriceFloat = 0.0;
      }
      
      // Pre-fill buy_price_previous => buy_price
      // Ostohinta tilauksella -sarakkeeseen kopioituu automaattisesti arvo, joka on Ostohinta toimittajalla -sarakkeessa
      if (!orderProductPriceInputValue || orderProductPriceInputValue == undefined || orderProductPriceInputValue == null) {
        orderProductPriceInputValue = orderProductPriceFloat;
      }
      
      // Tilattavan arvo yhteensä
      const order_total_value = round((orderProductAmount + customerOrdersCount) * orderProductPriceFloat, 2);
      
      const barcode = row?.stock_item?.barcode && row?.stock_item?.barcode.length > 0 ? row?.stock_item?.barcode : "-";
      
      let rowObj = {
        key: `${productId}-${productVariationId}`,
        id: `${productId}-${productVariationId}`,
        status: <Typography className="sk_font_text">{getStatusName(row?.status)}</Typography>,
        status_sort: row?.status,
        erp_id_sort: parseFloat(`${productId}.${productVariationId}`),
        mcf_product_id: row?.extra?.is_variation ? row?.product_fk?.id_mcf : row?.id_mcf,
        mcf_variation_id: row?.extra?.is_variation ? row?.id_mcf : "",
        name: {
          className: row?.extra?.is_variation ? "text-right" : "",
          content: (
            <>
              <Typography bold={!row?.extra?.is_variation} className="sk_name_field sk_font_text sk_text_wrap">
                {row?.name}
              </Typography>
            </>
          ),
        },
        name_sort: row?.name,
        code: (
          <Typography className="sk_code_field sk_font_text">
            <TextCutter text={row?.product_code} />
          </Typography>
        ),
        codes: !row?.has_variations && (
          <Input className="sk_input sk_font_text form-control-sm sk_input_product_supplier_code" disabled={loadingProducts || productSupplierCodeLock} name="product_supplier_code" type="select" value={product_supplier_code_id} onChange={(e) => handleProductSupplierCodeChange(e, row?.product_supplier_codes, productId, productVariationId)} onWheel={(e) => e.target.blur()}>
            { 
              // When multiple code options
              getProductSupplierCodesCount(row) > 1 ? (
              <option value=""></option>
              ) : null
            }
            {getProductSupplierCodeInputOptions(row, productSupplierCodeLock)}
          </Input>
        ),
        codes_sort: !row?.has_variations ? getProductSupplierCodeName() : "",
        batch: !row?.has_variations && (<Typography>{rowBatch}</Typography>),
        minimum_order_quantity: !row?.has_variations && (<Typography>{getOrderProductFieldValue("minimum_order_quantity")}</Typography>),
        buy_price_previous: <Typography title="supplier_code_purchase_order_product_price_previous">{buy_price_previous}</Typography>,
        buy_price: !row?.has_variations && <Input disabled={loadingProducts} className="sk_input form-control-sm" type="text" name="price" value={orderProductPriceInputValue} onBlur={(e) => handleInputFloatBlur(e, productId, productVariationId)} onChange={(e) => handleOrderProductChange(e, productId, productVariationId)} onWheel={(e) => e.target.blur()} />,
        purchase_price_weight_old: purchase_price_weight_old ? <Typography title="purchase_price_weight_old (a) orderProduct.purchase_price_weight_old b) product.purchase_price">{purchase_price_weight_old}&nbsp;€</Typography> : "-",
        purchase_price_weight: purchase_price_weight ? <Typography title="purchase_price_weight (calculated)">{purchase_price_weight}&nbsp;€</Typography> : "-",
        sell_price: <Typography>{sell_price}&nbsp;€</Typography>,
        stock: !row?.has_variations && <Typography color={getStockBalanceColor(row?.stock_item)}>{row?.stock_item?.quantity}</Typography>,
        order_amount: !row?.has_variations && <Input disabled={loadingProducts} className="sk_input form-control-sm" type="number" min={0} name="amount" value={orderProductAmount.toString()} onChange={(e) => handleOrderProductChange(e, productId, productVariationId)} onWheel={(e) => e.target.blur()} />,
        order_amount_sort: getOrderAmountSort(),
        order_amount_single: <Typography>{order_amount_single}</Typography>,
        order_amount_single_sort: order_amount_single,
        customer_orders: {
          className: customerOrdersCount && customerOrdersCount > 0 ? "lightGreenBackground" : "",
          content: (<>{getCustomerOrders(row, rowBatch)}</>),
        },
        complaints: {
          className: complaintsCount && complaintsCount > 0 ? "lightGreenBackground" : "",
          content: (<>{getProductComplaints(row, rowBatch)}</>)
        },
        complaints_sort: complaintsCount,
        demand: <Typography>{rowDemand}</Typography>,
        demand_sort: rowDemand,
        demand_supplier_code: <Typography>{rowDemandSupplierCode}</Typography>,
        demand_supplier_code_sort: rowDemandSupplierCodeSort,
        order_total_count: {
          className: orderTotalCountClassName,
          content: (
            <>{order_total_count}</>
          ),
        },
        order_total_count_sort: orderTotalCountSort,
        order_total_value: (
          <Typography>{order_total_value}&nbsp;€</Typography>
        ),
        availability: !row?.has_variations && <Typography>{row?.stock_item?.backorder_estimate ? row?.stock_item?.backorder_estimate : "-"} vrk</Typography>,
        reserved: <Typography>{row?.stock_item?.reserved}</Typography>,
        barcode: <Typography className="sk_font_text">{barcode}</Typography>,
        weight: <Typography>{row?.weight}</Typography>,
        weight_sort: row?.weight ? row?.weight : -1,
        brand_id: <Typography className="sk_font_text"><TextCutter text={getBrandName(row?.brand_id)} /></Typography>,
        brand_id_sort: getBrandName(row?.brand_id),
        stock_item_balance_limit: <Typography>{row?.stock_item?.balance_limit}</Typography>,
        stock_location: <Typography className="sk_font_text">{row?.stock_item?.location}</Typography>,
        stock_free: row?.stock_item?.balance,
        stock_free_sort: row?.stock_item?.balance ? row?.stock_item?.balance : 0,
        previous_order_count: row?.previous_order_count,
        previous_order_count_sort: row?.previous_order_count,
        sales_margin_pct: (
          <Typography title={sales_margin_pct_expression}>{sales_margin_pct ? sales_margin_pct : "-"}</Typography>
        ),
        sales_margin_pct_sort: sales_margin_pct ? sales_margin_pct : -1000.0,
        vat_rate: vat_rate,
        sales_manual: loadingProducts ? <Spinner /> : <Typography>{row?.sales_manual}</Typography>,
        sales_1m: <Typography>{row?.one_month_sale}</Typography>,
        sales_1m_sort: row?.one_month_sale,
        sales_3m: <Typography>{row?.three_month_sale}</Typography>,
        sales_3m_sort: row?.three_month_sale,
        sales_6m: <Typography>{row?.six_month_sale}</Typography>,
        sales_6m_sort: row?.six_month_sale,
        sales_9m: <Typography>{row?.nine_month_sale}</Typography>,
        sales_9m_sort: row?.nine_month_sale,
        sales_1y: <Typography>{row?.one_year_sale}</Typography>,
        sales_1y_sort: row?.one_year_sale,
        sales_2y: <Typography>{row?.two_year_sale}</Typography>,
        sales_2y_sort: row?.two_year_sale,
        sales_3y: <Typography>{row?.three_year_sale}</Typography>,
        sales_3y_sort: row?.three_year_sale,
        sales_5y: <Typography>{row?.five_year_sale}</Typography>,
        sales_5y_sort: row?.five_year_sale,
      }
      
      // Put the value here that we want to sort by
      if (sortingKey) {
        rowObj["sortValue"] = rowObj[sortingKey]
      }
      if (productVariationId > 0) {
        // rowObj["sortValue"] = row?.sort
        rowObj["sortFixed"] = row?.sort ? row?.sort : 0;
      }
      
      // Lets make hierarcy structure
      
      const getSortValue = (a, b) => {
        // Sort by main product always when sorting one of these columns
        if (["status_sort","name_sort","erp_id_sort","mcf_product_id","brand_id_sort"].includes(sortingKey)) {
          return a
        }
        else {
          // Put the value from child into the parent if bigger or smaller than current parent value (depending on asc/desc)
          let result = 0;
          if (sortingType === "string") {
            result = a.localeCompare(b)
          }
          else if (sortingType === "float") {
            result = parseFloat(a) - parseFloat(b)
          }
          else {
            result = parseInt(a) - parseInt(b)
          }
          
          if (result < 0) {
            return b
          }
          else {
            return a
          }
        }
      }
      
      const mainProductKey = `p${productId}v0`;
      // const fullProductKey = `p${productId}v${productVariationId}`;
      
      // Product / Päätuote
      if (productVariationId === 0) {
        rowsDict[mainProductKey] = {
          ...rowObj,
          children: [],
        }
      }
      else { // Variation
        if (rowsDict.hasOwnProperty(mainProductKey)) {
          rowsDict[mainProductKey] = {
            ...rowsDict[mainProductKey],
            children: [
              ...rowsDict[mainProductKey].children,
              rowObj
            ],
            sortValue: getSortValue(rowsDict[mainProductKey].sortValue, rowObj.sortValue),
          }
        }
        else {
          console.log("ERROR WRONG ROW ORDER, PARENT PRODUCT MUST COME FIRST IN HERE")
        }
      }
      
    })
    
    // Make list of parent products, to do sorting with them
    let sortedParentRows = []
    for (const [key, value] of Object.entries(rowsDict)) {
      sortedParentRows.push(rowsDict[key])
    }
    
    // Client sorting
    
    const sortRowsChildren = (_rows) => {
      return _rows.sort((a, b) => {
          return parseInt(a["sortFixed"]) > parseInt(b["sortFixed"]) ? 1 : -1
      })
    }
    
    sortedParentRows = sortedParentRows.sort((a, b) => {
      try {
        if (sorting?.sortingType === "string") {
          return a["sortValue"].localeCompare(b["sortValue"])
        }
        else if (sorting?.sortingType === "float") {
          return parseFloat(a["sortValue"]) - parseFloat(b["sortValue"])
        }
        else {
          return parseInt(a["sortValue"]) > parseInt(b["sortValue"]) ? 1 : -1
        }
      }
      catch {
        return -1
      }
    })
    
    if (sortingReverse) {
      sortedParentRows = sortedParentRows.reverse()
    }
    
    // Add children (variations) to the table rows
    let sortedRows = []
    sortedParentRows.map(row => {
      sortedRows.push(row)
      
      // Sort children
      if (row?.children && row.children.length > 0) {
          const children = sortRowsChildren(row.children)
          children.map(rowChild => {
            sortedRows.push(rowChild)
          })
      }
      
      /*
      row.children.map(rowChild => {
        sortedRows.push(rowChild)
      })
      */
      
      
      // Sort children
      /*
      if (row.children && row.children.length > 0) {
          let sortedChildren = []
          sortedChildren = row.children.sort((a, b) => {
              try {
                if (sorting?.sortingType === "string") {
                  return a["sortValue"].localeCompare(b["sortValue"])
                }
                else if (sorting?.sortingType === "float") {
                  return parseFloat(a["sortValue"]) - parseFloat(b["sortValue"])
                }
                else {
                  return parseInt(a["sortValue"]) > parseInt(b["sortValue"]) ? 1 : -1
                }
              }
              catch {
                return -1
              }
          })
          
          // Push children to rows
          sortedChildren.map(rowChild => {
            sortedRows.push(rowChild)
          })
      }
      */
    })
    
    return sortedRows;
  }, [rows, orderData, filters, sorting, loadingProducts, loadingOrderProduct, updateOrderProductField])
  
  // Form
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  const handleDateFilterChange = ({ target }) => {
    const { value, name } = target;
    
    setDateFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  const handleDateFilterSubmit = () => {
    setLoadingOrderProduct({})
    
    setBackendFilters(s => ({
      ...s,
      ...filters,
      ...dateFilters,
    }))
  }
  
  const handleConfirmPurchaseOrder = () => {
    httpConfirmPurchaseOrder(values.id).then(res => {
      // OK
    })
  }
  
  // Modal: Add Product
  const [modalAddProductOpen, setModalAddProductOpen] = useState(false)
  
  const modalToggleAddProduct = () => {
    setModalAddProductOpen(s => !s);
  }
  
  const resolveAddProduct = () => {
    setModalAddProductOpen(false)
  }

  const rejectAddProduct = () => {
    setModalAddProductOpen(false)
  }
  
  // Save / Save and publish
  
  const updateSummaryFields = (data) => {
    setFieldValue("currency_rate", data?.currency_rate)
    setFieldValue("total_order_value", data?.total_order_value)
  }
  
  const submitPurchaseOrder = (publish) => {
    setUnsavedChanges(false)
    
    let orderDataFiltered = {};
    
    // Validate OrderData
    
    for (const [key] of Object.entries(orderData)) {
      const orderProduct = orderData[key]
      
      if (orderProduct?.hasVariations === false) {
      
        const orderCount = getOrderProductOrderCount(orderProduct)
        
        if (orderCount > 0 && (!orderProduct?.product_supplier_code_id || orderProduct?.product_supplier_code_id === -1)) {
          notify({ 
            title:t("Valitse toimittajan tuotenumero"), 
            message:`${orderProduct?.name}`,
            type: "danger"
          })
          setSubmitting(false);
          return;
        }
        
        // Saving only rows where is orderable data
        // orderProduct?.id > 0 IF there has been already this row and we want to set it back to 0, we need to save this change
        if ((orderProduct?.id && orderProduct?.id > 0) || orderCount > 0 || (orderProduct?.customer_orders_amount_total && orderProduct?.customer_orders_amount_total > 0) || (orderProduct?.complaints_amount_total && orderProduct?.complaints_amount_total > 0)) {
          if (!orderProduct?.amount || orderProduct?.amount == "") {
            orderProduct.amount = 0
          }
          
          // Price BEGIN
          // previous buy price
          const buy_price_previous = orderProduct?.supplier_code_purchase_order_product_price_previous;
        
          // buy_price
          let orderProductPrice = orderProduct?.price
          if (orderProductPrice && orderProductPrice >= 0) {
            orderProductPrice = parseFloat(orderProductPrice);
          }
          else if (buy_price_previous) {
            // Copy value
            orderProductPrice = buy_price_previous;
          }
          else {
            orderProductPrice = 0.0;
          }
          orderProduct.price = orderProductPrice;
          // Price END
          
          
          orderDataFiltered[key] = orderProduct;
        }
        
      }
    }
    
    let data = {
      ...values,
      supplier: {id: values?.supplier, name: "aaa"},
      order_data: orderDataFiltered,
    };
    
    setSubmitting(true)
    
    if (values?.id) {
      httpUpdatePurchaseOrder(values.id, data).then(res => {
        setSubmitting(false);
        notify({ title:t("Ostotilaus"), message:t("Tallennettu onnistuneesti")})
        
        // Update summary
        httpGetPurchaseOrderSummary(values.id).then(summaryRes => {
          updateSummaryFields(summaryRes?.data);
        });
        
        if (publish === true) {
          setOrderId(res?.data?.id);
          handleOpenModalPublish(res?.data?.id);
        }
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
    }
    else {
      httpCreatePurchaseOrder(data).then(res => {
        setSubmitting(false);
        
        notify({ title:t("Ostotilaus"), message:t("Luotu onnistuneesti")});
        
        setOrderId(res?.data?.id);
        setFieldValue("id", res?.data?.id);
        
        // Update summary
        httpGetPurchaseOrderSummary(res?.data?.id).then(summaryRes => {
          updateSummaryFields(summaryRes?.data);
        });
        
        if (publish === true) {
          handleOpenModalPublish(res?.data?.id);
        }
        else {
          // history.push(`/dashboard/purchase_orders/edit/${res?.data?.id}/`);
        }
        
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
    }
  }
  
  const handleSave = (publish) => {
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        submitPurchaseOrder(publish)
      }
      else {
        setTouched(res);
        setErrors(res);
      }
    })
  }
 
  // Modal: Product Delivery
  
  const [modalProductDeliveryOpen, setModalProductDeliveryOpen] = useState(false)
  const [modalProductDeliveryId, setModalProductDeliveryId] = useState()
  
  const toggleModalProductDelivery = () => {
    setModalProductDeliveryOpen(s => !s);
  }
  
  const handleModalProductDeliveryResolve = () => {
    setModalProductDeliveryOpen(false);
    setModalProductDeliveryId(null);
    getPurchaseOrderProducts();
  }
  
  const handleModalProductDeliveryReject = () => {
    setModalProductDeliveryOpen(false);
    setModalProductDeliveryId(null);
  }
  
  // Alert: Delete purchase order
  
  const [deletePurchaseOrderAlert, setDeletePurchaseOrderAlert] = useState();
  
  const handleOpenPurchaseOrderDeleteAlertConfirm = () => {
    setUnsavedChanges(false)
    httpDeletePurchaseOrder(values?.id).then(res => {
      history.push(`/dashboard/purchase_orders/list/draft`);
    })
  }
  
  const handleOpenPurchaseOrderDeleteAlertCancel = () => {
    setDeletePurchaseOrderAlert(null)
  }
  
  const handleOpenPurchaseOrderDeleteAlert = () => {
    setDeletePurchaseOrderAlert(
      <AlertModal
        title={t("Vahvista poisto")}
        onConfirm={() => handleOpenPurchaseOrderDeleteAlertConfirm()}
        onCancel={() => handleOpenPurchaseOrderDeleteAlertCancel()}
        showCancel={true}
        cancelBtnText={t("Peruuta")}
        confirmBtnText={t("Poista")}
      >
        {t("Poistetaanko ostotilaus?")}
      </AlertModal>
    )
  }

  const [orderProductsCount, setOrderProductsCount] = useState(0)
  
  const getOrderProductsOrderCount = useCallback(() => {
    let _count = 0;
    if (orderData) {
      
      for (const [key, value] of Object.entries(orderData)) {
          const orderProduct = orderData[key]
          const orderCount = getOrderProductOrderCount(orderProduct, {includeComplaints:false, includeCustomerOrders:false})
          _count += orderCount;
      }
    }
    return _count
  }, [orderData, getOrderProductOrderCount])
  
  const [orderDemandCount, setOrderDemandCount] = useState(0) // Demand of all rows
  
  const getOrderProductsDemandCount = useCallback(() => {
    let _countR = 0;
    if (orderData) {
      for (const [key, value] of Object.entries(orderData)) {
          if (value && value?.demand > 0) {
            _countR += value?.demand
          }
      }
    }
    return _countR
  }, [orderData])
  
  useEffect(() => {
    setOrderProductsCount(getOrderProductsOrderCount())
  }, [orderData, getOrderProductsOrderCount])
  
  useEffect(() => {
    setOrderDemandCount(getOrderProductsDemandCount()) // demand / tarve
  }, [orderData, getOrderProductsDemandCount])
  
  return (
    <>
      <Prompt
        when={unsavedChanges}
        message={t("purchase_order_edit_unsaved_changes", "Sinulla on tallentamattomia tietoja, poistutaanko silti?")}
      />
      <Row>
        <Col>
          <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
            { values?.id ? (
              <>
                <BreadcrumbItem>
                  <Link to={`/dashboard/purchase_orders/list/${getTabKey()}`}>
                    {t("Ostotilaukset")}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to={`/dashboard/purchase_orders/list/${getTabKey()}`}>
                    {getTabName()}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  {t("Tilaus")} {values?.id}
                </BreadcrumbItem>
              </>
            ) : (
              <>
                <BreadcrumbItem>
                  <Link to={`/dashboard/purchase_orders/list/draft`}>
                    {t("Ostotilaukset")}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  {t("Luo ostotilaus")}
                </BreadcrumbItem>
              </>
            )}
          </Breadcrumb>
        </Col>
      </Row>
      
      <Row>
        <Col>
        { values.id ? (
          <PageHeader title={t("Muokkaa ostotilausta")}></PageHeader>
        ) : (
          <PageHeader title={t("Luo ostotilaus")}></PageHeader>
        )}
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            <div className="px-2">
              { values?.id && values?.id > 0 ? (
              <Link className="small" to={`/dashboard/purchase_price_calculator/${values?.id}`}>
                {t("Ostohintalaskuri")}
                {/*}
                <Typography variant="small">{t("Ostohintalaskuri")}</Typography>
              */}
              </Link>
              ) : null}
            </div> 
            <div className="px-2">
              <TableColumnToggle tableName="PurchaseOrderEdit" userId={myUser?.id} headers={headers} setHeaders={setHeaders} setHeaderValue={setHeaderValue} />
            </div>
          </div>
        </Col>
      </Row>
      
      { values.id ? (
      <>
        <Row>
          <Col>{t('Tilauksen tuotteiden kokonaishinta alkuperäisvaluutassa')} ({values?.purchase_order_currency}): {values?.total_order_value}</Col>
        </Row>
        <Row>
          <Col>{t('Tuotteiden kokonaishinta euroina (EUR)')}: {round(values?.total_order_value / values?.currency_rate, 2)}</Col>
        </Row>
      </>
      ) : null }
      
      <div>
        <Row className="d-flex justify-content-between">
          <Col className="col-auto">
              <Row className="mb-2">
                <Col className="col-12 col-sm-12 col-md-auto">
                  <Input label={t("purchase_order_edit_supplier", "Tavarantoimittaja")} type="select" {...getFormikInputProps("supplier")} disabled={values?.id}>
                    <option value=""></option>
                    {getSupplierOptions()}
                  </Input>
                </Col>
                
                <Col className="d-none d-lg-block"></Col>
                
              </Row>
              
              { values.supplier && (
                <Row className="sk_purchase_order_edit_filters">
                  <Col className="col-12 col-sm-12 col-md-auto">
                    <Input label={t("Tuotekategoria")} type="select" name="category" onChange={handleFilterChange} value={filters?.category}>
                      <option value="">{t("Kaikki")}</option>
                      {getCategoryOptions()}
                    </Input>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-auto">
                    <Input label={t("Tuotemerkki")} type="select" name="brand_id" onChange={handleFilterChange} value={filters?.brand_id}>
                      <option value="">{t("Kaikki")}</option>
                      {getBrandOptions()}
                    </Input>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-auto">
                    <Input label={t("Varasto")} name="stock_balance_max" type="select" onChange={handleFilterChange} value={filters?.stock_balance_max}>
                      <option value="">{t("Kaikki")}</option>
                      <option value="10">{t("Vähän jäljellä")}</option>
                      <option value="0">{t("Loppu varastosta")}</option>
                    </Input>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-auto">
                    <div className="d-flex flex-row align-items-end">
                      <div className="p-0"><Input label={t("Myynti alkaen")} name="sales_date_start" type="date" value={dateFilters?.sales_date_start} onChange={handleDateFilterChange} /></div>
                      <div className="p-0"><Input label={t("Myynti päättyen")} name="sales_date_end" type="date" value={dateFilters?.sales_date_end} onChange={handleDateFilterChange} /></div>
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-auto">
                    <div className="h-100 d-flex flex-row align-items-end">
                      <div className="p-0">
                        <Button className="text-nowrap" disabled={loadingProducts} onClick={() => handleDateFilterSubmit()}>{t("filter_dates_submit", "Suodata")}</Button>
                      </div>
                    </div>
                  </Col>
                  <Col className="d-none d-lg-block"></Col>
                </Row>
              )}

          </Col>
          <Col className="text-right" style={{maxWidth:"256px"}}>
            { values.supplier && (
              <div className="d-flex flex-column">
                { values?.state < 3 && (
                <div className="p-2">
                  { /* orderProductsCount === 0 */ }
                  <Button className="text-nowrap" loading={isSubmitting} disabled={isSubmitting} onClick={() => handleSave(false)}>{t("Tallenna")} { values?.state < 2 ? t("luonnos") : "" }</Button>
                  { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }  
                </div>
                )}
                { values?.state === 1 && (
                  <div className="p-2">
                    <Button className="text-nowrap" disabled={!values?.id || values?.state > 1} onClick={handleOpenPurchaseOrderDeleteAlert} variant="danger">{t("Poista ostotilaus")}</Button>
                  </div>
                )}
                { !values?.state && (
                  <div className="p-2">
                    {/* || orderProductsCount === 0 */ }
                    <Button className="btn-success text-nowrap" loading={isSubmitting} disabled={isSubmitting} onClick={() => handleSave(true)}>{t("Tallenna ja lähetä ostotilaus")}</Button>
                    { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }  
                  </div>
                )}
                { values?.state === 1 && (
                  <div className="p-2">
                    {/* || orderProductsCount === 0 */ }
                    <Button className="text-nowrap" loading={isSubmitting} disabled={!values?.id || values?.state > 1} onClick={() => handleSave(true)} variant="success">{t("Tallenna ja lähetä ostotilaus")}</Button>
                  </div>
                )}
                { values?.state === 2 && (
                  <div className="p-2">
                    <Button className="text-nowrap" disabled={values?.confirmed_by_orderer} onClick={() => validateForm().then(() => handleConfirmPurchaseOrder())} variant="success">
                    { values?.confirmed_by_orderer ? t("Vahvistettu") : t("Vahvista ostotilaus") }
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
        
        <Row>
          <Col className="mb-1">
            <div style={{height: 48}}>
              { loadingProducts ? (
              <div>
                <Spinner />
                <div>{t("Loading products")}...</div>
              </div>
              ) : null }
            </div>
          </Col>
        </Row>
        
      </div>
      
      { values?.supplier && loadingFirstLoaded && (
        <TableAuto
          color="dark"
          showId={false}
          checkboxes={false}
          headers={headers}
          rows={getTableRows()}
          showPaginationLimitSelect={false}
          filters={filters}
          setFilters={setFilters}
          tableStickyHeader={true}
          secondHeader={getSecondHeader()}
          sortBackend={false}
          sorting={sorting}
          setSorting={setSorting}
        />
      )}
      
      <Modal
        title={t("Lisää tuote")}
        isOpen={modalAddProductOpen}
        toggleModal={modalToggleAddProduct}
      >
        <PurchaseOrderAddProduct purchaseOrderId={values?.id} supplierId={values?.supplier} resolve={resolveAddProduct} reject={rejectAddProduct} />
      </Modal>
      
      <Modal
        size="modal-xl"
        title={t("Lisää tilausmäärät tuotteelle")}
        isOpen={modalAddProductOrderOpen}
        toggleModal={toggleAddProductOrder}
        closeModal={rejectAddProductOrder}
      >
        <PurchaseOrderProductOrder
          purchaseOrderId={values?.id}
          purchaseOrderProduct={selectedPurchaseOrderProduct}
          updateOrderProduct={updateOrderProduct}
          updateOrderProductField={updateOrderProductField}
          handleResolve={resolveAddProductOrder}
          handleReject={rejectAddProductOrder}
          readOnly={false}
          supplierId={values.supplier}
        />
      </Modal>
      
      <Modal
        title={t("Tavaran saapumiskuittaus")}
        isOpen={modalProductDeliveryOpen}
        toggleModal={toggleModalProductDelivery}
      >
        <PurchaseOrderProductDelivery
          purchaseOrderId={values?.id}
          purchaseOrderProductId={modalProductDeliveryId}
          handleResolve={handleModalProductDeliveryResolve}
          handleReject={handleModalProductDeliveryReject}
        />
      </Modal>
      
      {deletePurchaseOrderAlert}
      
    </>
  );
}

const defaultValues = {
  id: null,
  supplier: null,
  state: 0,
}

// Formik wrapper
const PurchaseOrderEditFormik = withFormik({
  
  validateOnMount: true,
  mapPropsToValues: props => {
    const { preSetValues } = props;
    if ( preSetValues) {
      return preSetValues;
    } else {
      return defaultValues;
    }
  },
  validationSchema: props => {
    return Yup.object().shape({
      
    });
  },
  handleSubmit: (values, { setSubmitting, setErrors, props }) => {
    
  },
  displayName: "BasicForm"

})(PurchaseOrderEdit)

// View component (parent)
const PurchaseOrderEditView = ({match, history}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  
  const [orderId, setOrderId] = useState()
  const [orderData, setOrderData] = useState({})
  
  const getPurchaseOrder = useCallback((id) => {
    setLoading(true)
    httpGetPurchaseOrder(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
        supplier: res.data?.supplier?.id,
      });
    }).finally(response => {
      setLoading(false);
    })
  }, [])
  
  useEffect(() => {
    if (match && match?.params?.purchaseOrderId) {
      setOrderId(match?.params?.purchaseOrderId)
      getPurchaseOrder(match?.params?.purchaseOrderId)
    }
  }, [match])
  
  // Modal: Publish order
  const [modalPublishOpen, setModalPublishOpen] = useState(false)
  
  const toggleModalPublishOpen = () => {
    setModalPublishOpen(s => !s);
  }
  
  const handleOpenModalPublish = () => {
    setModalPublishOpen(true)
  }

  const resolvePublish = () => {
    setUnsavedChanges(false)
    setModalPublishOpen(false)
    if (orderId) {
      history.push(`/dashboard/purchase_orders/list/sent`);
    }
  }

  const rejectPublish = () => {
    setModalPublishOpen(false)
    if (orderId) {
      history.push(`/dashboard/purchase_orders/edit/${orderId}/`);
    }
  }
  
  const getOrderProductOrderCount = (orderProduct, args) => {
    let count = (orderProduct?.amount && orderProduct?.hasVariations === false) ? parseInt(orderProduct?.amount) : 0;
    if (args?.includeComplaints === true) {
      count += orderProduct?.complaints_count ? parseInt(orderProduct?.complaints_count) : 0
    }
    if (args?.includeCustomerOrders === true) {
      count += orderProduct?.customer_orders_count ? parseInt(orderProduct?.customer_orders_count) : 0
    }
    return count;
  }
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <PurchaseOrderEditFormik
        t={t}
        history={history}
        preSetValues={preSetValues}
        notify={notify}
        orderData={orderData}
        setOrderData={setOrderData}
        setOrderId={setOrderId}
        handleOpenModalPublish={handleOpenModalPublish}
        getOrderProductOrderCount={getOrderProductOrderCount}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
      
      <Modal
        title={t("Haluatko varmasti lähettää tilauksen")}
        isOpen={modalPublishOpen}
        toggleModal={toggleModalPublishOpen}
      >
        <PurchaseOrderPublish
          purchaseOrderId={orderId}
          handleResolve={resolvePublish}
          handleReject={rejectPublish}
          description={preSetValues?.description}
          existingAttachment={preSetValues?.attachment}
        />
      </Modal>
    </>
  
  )
}

export default PurchaseOrderEditView;
