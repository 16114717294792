import http from './api'

const url = '/customers'

export const httpLoginMcf = (data, config={}) => {
  return http({
    url: `/customers/login_mcf/`,
    method: 'POST',
    data,
    ...config,
  })
}

export const httpGetCustomers = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params,
  })
}

export const httpGetCustomerUserRoles = (id, params) => {
  return http({
    url: `${url}/${id}/user_roles/` ,
    method: 'GET',
    params,
  })
}

export const httpCreateCustomerUserRole = (customerId, data) => {
  return http({
    url: `${url}/${customerId}/user_roles/` ,
    method: 'POST',
    data,
  })
}

export const httpGetMyCustomer = (params) => {
  return http({
    url: `${url}/me/` ,
    method: 'GET',
    params,
  })
}

// https://sarkain.visualstudio.com/_git/Tradesoft-server?path=/docs/api/customers/Get-emails.md&_a=preview
export const httpGetCustomerEmails = (id, params) => {
  return http({
    url: `${url}/${id}/get_emails/` ,
    method: 'GET',
    params,
  })
}
