import http from './api'

const url = '/campaigns'

export const httpGetCampaigns = (params, config={}) => {
  return http({
    url: `${url}/`,
    method: 'GET',
    params,
    ...config,
  })
}

export const httpGetCampaign = (id) => { // Working?
  return http({
    url: `${url}/${id}/`,
    method: 'GET',
  })
}

export const httpCreateCampaign = (data, config={}) => {
  return http({
    url: `${url}/`,
    method: 'POST',
    data,
    ...config,
  })
}

export const httpUpdateCampaign = (id, data, config={}) => {
  return http({
    url: `${url}/${id}/`,
    method: 'PATCH',
    data,
    ...config,
  })
}

export const httpGetAllMcfCampaigns = (params, config={}) => {
  return http({
    // url: `/campaigns/get_all_mcf_campaign_names_and_ids/`,
    url: `/campaigns/get_all_mcf_campaigns/`,
    method: 'GET',
    params,
    ...config,
  })
}

export const httpGetMcfCampaign = (id, config={}) => {
  return http({
    url: `/campaigns/get_mcf_campaign/${id}/`,
    method: 'GET',
    ...config,
  })
}


