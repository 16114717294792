import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { generatePath } from "react-router";
import {
  Typography,
  Link,
  IconButton,
  Badge,
  Modal,
  Breadcrumb,
  BreadcrumbItem,
  Popover,
  Pad,
} from "stories/components";
import { useAppContext } from "contexts/AppContext"
import { PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetPurchaseOrders, httpGetPurchaseOrdersStatuses } from "services/purchase_orders"
import { getDate, getDateAndTime } from "services/datetime"
import PurchaseOrderPaymentEdit from "views/dashboard/PurchaseOrderPaymentEdit/PurchaseOrderPaymentEdit"

import SupplierOrderShippingEdit from "views/dashboard/SupplierOrderShippingEdit/SupplierOrderShippingEdit"

const PurchaseOrders = ({match, history}) => {
  
  const { enums, getTabNameByKey, getTabIdByKey } = useAppContext()
  const { t } = useTranslation();
  
  // Table
  const [loading, setLoading] = useState(false)
  const [statuses, setStatuses] = useState({})
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "-id",
    buyer: true,
  })
  
  const getPurchaseOrdersStatuses = () => {
    const params = {
      buyer: true,
    }
    httpGetPurchaseOrdersStatuses(params).then(res => {
      setStatuses(res?.data)
    })
  }
  
  const getPurchaseOrders = useCallback(() => {
    getPurchaseOrdersStatuses()
    
    let params = {
      ...filters
    }
    if (params.hasOwnProperty("state__in") === true) {
      params.state = undefined
    }
    
    setLoading(true)
    httpGetPurchaseOrders(params).then(res => {
      setRows(res?.data)
      setLoading(false)
    }, errorResponse => {
      setLoading(false)
    })
  }, [filters])
  
  useEffect(() => {
    const tab = match?.params?.tab;
    if (tab) {
      setFilters(s => ({
        ...s,
        state: getTabIdByKey(tab),
      }))
    }
  }, [match, getTabIdByKey])
  
  useEffect(() => {
    if (filters?.state) {
      getPurchaseOrders();
    }
  }, [filters, getPurchaseOrders])
  
  const getTabName = useCallback(() => {
    const tab = match?.params?.tab;
    return getTabNameByKey(tab)
  }, [match, getTabNameByKey])
  
  const getPurchaseOrderStateName = useCallback((stateKey) => {
    const value = enums && enums?.purchase_orders?.purchase_order?.state.filter((item) => {
        return item.key === stateKey
      })[0].value
    return t(value) /* i18next-extract-disable-line */
  }, [enums, t])
  
  const handleEdit = useCallback((id) => {
    history.push(`/dashboard/purchase_orders/edit/${id}/`);
  }, [history])
  
  const handleReview = useCallback((id) => {
    history.push(`/dashboard/purchase_orders/review/${id}/`);
  }, [history])
  
  const headers = [
    { label: "ID", key: "id"},
    { label: t("Toimenpiteet"), key: "action_badges"},
    // { label: "", key: "actions"},
    { label: t("Tavarantoimittaja"), key: "supplier"},
    { label: t("Sisältö"), key: "content"},
    { label: t("Tapahtumat"), key: "events"},
  ];
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {
      
      const actionBadgeValue = row?.purchase_order_product_rows_count - row?.products_confirmed_by_orderer_count + row?.products_comments_orderer_unread_count;
      
      return {
        id: row?.id,
        supplier: (
          <>
            <Typography bold>
              { row?.state < 2 && (
                <Link to={`/dashboard/purchase_orders/edit/${row?.id}/`}>
                {row?.supplier?.name}
                </Link>
              )}
              { row?.state >= 2 && (
                <Link to={`/dashboard/purchase_orders/review/${row?.id}/`}>
                {row?.supplier?.name}
                </Link>
              )}
            </Typography>
            <Typography>{t("Viimeksi paikalla")}: -</Typography>
          </>
        ),
        content: (
          <>
            <div><Typography bold className="d-inline-block">{t("Tuotenimikkeitä")}:</Typography> <Typography className="d-inline-block">{row?.total_order_count}{t("kpl")} ({t("Reklamaatioita")}: {row?.products_complaints_amount_total}{t("kpl")})</Typography></div>
            <div><Typography bold className="d-inline-block">{t("Kokonaisarvo")}:</Typography> <Typography className="d-inline-block">{row?.total_order_value}{row?.purchase_order_currency} ({row?.calculated_profit}% {t("kate")})</Typography></div>
          </>
        ),
        events: (
          <>
            <div><Typography bold className="d-inline-block">{t("Tila")}:</Typography> <Typography className="d-inline-block">{getPurchaseOrderStateName(row?.state)} {row?.state_updated && getDateAndTime(row?.state_updated)}</Typography></div>
            <Link isExternal={true} id={`popover${row?.id}`}>{t("näytä tapahtumat")}</Link>
            <Popover
                placement="top"
                target={`popover${row?.id}`}
            >
              <div><Typography bold className="d-inline-block">{t("Lähetetty")}:</Typography> <Typography className="d-inline-block">{row?.state_sent ? getDateAndTime(row?.state_sent) : (<Badge color="danger">{t("Luonnos")}</Badge>)}</Typography></div>
              <div><Typography bold className="d-inline-block">{t("Tavarantoimittaja lukenut")}:</Typography> <Typography className="d-inline-block">{row?.read_last_supplier ? getDateAndTime(row?.read_last_supplier) : (<Badge color="danger">{t("Ei avattu")}</Badge>)}</Typography></div>
              <div><Typography bold className="d-inline-block">{t("Tavarantoimittaja käsitellyt")}:</Typography> <Typography className="d-inline-block">{row?.confirmed_by_supplier_dt ? getDateAndTime(row?.confirmed_by_supplier_dt) : <Badge color="danger">{t("Odottaa hyväksyntää")}</Badge>}</Typography></div>
              <div><Typography bold className="d-inline-block">{t("Vahvistettu")}:</Typography> <Typography className="d-inline-block">{row?.state_delivery ? getDateAndTime(row?.state_delivery) : "-"}</Typography></div>
              { row?.state > 1 && (
                <div><Typography bold className="d-inline-block">{t("Maksettu")}:</Typography> <Typography className="d-inline-block">{row?.payment_paid_state === 3 ? getDate(row?.payment_paid_state_dt) : row?.payment_paid_state === 2 ? (<Badge color="warning">{t("Maksettu osittain")}</Badge>) : (<Badge color="danger">{t("Odottaa maksua")}</Badge>)}</Typography></div>
              )}
              { row?.state > 1 && (
                <div><Typography bold className="d-inline-block">{t("Toimitettu")}:</Typography> <Typography className="d-inline-block"><Badge color="danger">{t("Odottaa, että tavarantoimittaja lähettää tuotteet")}</Badge></Typography></div>
              )}
              { row?.state >= 5 && (
                <div>
                  <Typography bold className="d-inline-block">{t("Toimitettu")}:</Typography> 
                  <Typography className="d-inline-block">&nbsp;{row?.products_delivered_count}/{row?.products_count}</Typography>
                  { row?.products_delivered_count < row?.products_count && (
                    <>
                      &nbsp;<Typography className="d-inline-block"><Badge color="danger">{t("Odottaa tuotteita")}</Badge></Typography>
                    </>
                  )}
                </div>
              )}
            </Popover>
          </>
        ),
        action_badges: (
        <>
          { row.state === 1 && (
            <>
              <Pad 
                onClick={() => handleEdit(row?.id)}
                icon="fa-exclamation-circle" 
                color="warning"
              >
                {t("Luonnos")}
              </Pad>
              <br />
            </>
          )}
          
          { row.state > 1 && (
          <>
            { !row?.confirmed_by_orderer || actionBadgeValue > 0 ? (
              <>
                <Pad 
                  onClick={() => handleReview(row?.id)}
                  icon="fa-exclamation-circle" 
                  color="danger" 
                  badgeColor="danger" 
                  badgeValue={actionBadgeValue}
                >
                  {t("Käsittele tilaus")}
                </Pad>
                <br />
              </>
            ) : (
              <>
              { !row?.confirmed_by_supplier && (
                <>
                  <Pad 
                    onClick={() => handleReview(row?.id)}
                    icon="fa-exclamation-circle"
                    color="warning"
                  >
                    {t("Odottaa")}
                    <br />
                    {t("hyväksyntää")}
                  </Pad>
                  <br />
                </>
              )}
              </>
            )}
            
            { row.state > 2 && (
            <>
              { row?.confirmed_by_orderer && row?.confirmed_by_supplier && (
                <>
                { row.state < 4 ? (
                  <>
                    <Pad 
                      onClick={() => handleOpenModalShipping(row?.id)}
                      icon="fa-exclamation-circle"
                      color="warning"
                    >
                      {t("Odottaa")}<br />{t("seurantatietoja")}
                    </Pad>
                    <br />
                  </>
                ) : (
                  <>
                  { !row?.all_products_delivered && (
                    <>
                      <Pad 
                        onClick={() => handleReview(row?.id)}
                        icon="fa-exclamation-circle" 
                        color="warning"
                      >
                        {t("purchase_orders_add_delivery","Kirjaa toimitus")}
                      </Pad><br />
                    </>
                  )}
                  </>
                )}
                { row?.payment_paid_state < 3 && (
                  <>
                    <Pad 
                      onClick={() => handleOpenModalPayment(row?.id)}
                      icon="fa-exclamation-circle"
                      color="warning"
                    >
                      {t("Odottaa maksua")}
                    </Pad>
                    <br />
                  </>
                )}
                </>
              )}
            </>
            )}
            
          </>
          )}
        </>
        ),
        actions: (
          <>
            { row?.state < 2 && (
              <IconButton size="lg" onClick={() => handleEdit(row?.id)} iconName="pen" />
            )}
            { row?.state === 2 && (
              <IconButton size="lg" onClick={() => handleReview(row?.id)} iconName="pen" badgeColor="danger" badgeValue={row?.confirmed_by_orderer === false ? "!" : null} />
            )}
            { row?.state === 3 && (
              <IconButton size="lg" onClick={() => handleReview(row?.id)} iconName="pen" badgeColor="danger" badgeValue={row?.payment_paid_state === 1 && row?.payment_billing_details ? "!" : null} />
            )}
            { row?.state === 4 && (
              <IconButton size="lg" onClick={() => handleReview(row?.id)} iconName="pen" />
            )}
            { (row?.state === 5 || row?.state === 6)&& (
              <IconButton size="lg" onClick={() => handleReview(row?.id)} iconName="pen" badgeColor="danger" badgeValue={row?.products_delivered_count < row?.products_count ? "!" : null} />
            )}
            { row?.state === 7 && (
              <IconButton size="lg" onClick={() => handleReview(row?.id)} iconName="eye" />
            )}
            { row?.confirmed_by_orderer === true && row?.confirmed_by_supplier === true && (
              <IconButton size="lg" onClick={() => handleOpenModalPayment(row?.id)} iconName="shopping-cart" badgeColor="danger" badgeValue={!row?.state_paid && "!"} />
            )}
          </>
        ),
      }
    })
  }, [rows, getPurchaseOrderStateName, handleEdit, handleReview, t])
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  // Modal: Payment
  
  const [modalOrderPaymentOpen, setModalOrderPaymentOpen] = useState(false)
  const [modalOrderPaymentId, setModalOrderPaymentId] = useState()
  
  const toggleModalOrderPayment = () => {
    setModalOrderPaymentOpen(s => !s);
  }
  
  const handleOpenModalPayment = (id) => {
    setModalOrderPaymentOpen(true)
    setModalOrderPaymentId(id);
  }
  
  const handleOrderPaymentResolve = () => {
    setModalOrderPaymentOpen(false);
    setModalOrderPaymentId(null);
    getPurchaseOrders();
  }
  
  const handleOrderPaymentReject = () => {
    setModalOrderPaymentOpen(false);
    setModalOrderPaymentId(null);
  }
  
  // Modal: Shipping
  
  const [modalOrderShippingOpen, setModalOrderShippingOpen] = useState(false)
  const [modalOrderShippingId, setModalOrderShippingId] = useState()
  
  const toggleModalOrderShipping = () => {
    setModalOrderShippingOpen(s => !s);
  }
  
  const handleOpenModalShipping = (id) => {
    setModalOrderShippingOpen(true)
    setModalOrderShippingId(id);
  }
  
  const handleOrderShippingResolve = () => {
    setModalOrderShippingOpen(false);
    setModalOrderShippingId(null);
    getPurchaseOrders();
  }
  
  const handleOrderShippingReject = () => {
    setModalOrderShippingOpen(false);
    setModalOrderShippingId(null);
  }
  
  
  return (
    <>
      <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
        <BreadcrumbItem>
          {t("Ostotilaukset")}
        </BreadcrumbItem>
        <BreadcrumbItem>
          {getTabName()}
        </BreadcrumbItem>
      </Breadcrumb>
      
      <PageHeader title={t("Ostotilausten hallinta")}></PageHeader>
      
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "draft" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              tab: "draft"
            })}
          ><i className="fas fa-exclamation-circle"></i> {t("Luonnokset")} ({statuses?.status_counts?.state_1})</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "sent" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              tab: "sent"
            })}
          ><i className="fas fa-briefcase"></i> {t("Lähetetty")} ({statuses?.status_counts?.state_2})</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "inprogress" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              tab: "inprogress"
            })}
          ><i className="far fa-calendar-check"></i> {t("Käsittelyssä")} ({statuses?.status_counts?.state_3})</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "ondelivery" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              tab: "ondelivery"
            })}
          ><i className="fas fa-truck"></i> {t("Saapuneet / matkalla")} ({statuses?.status_counts?.state_4})</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "completed" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              tab: "completed"
            })}
          ><i className="fas fa-shopping-basket"></i> {t("Valmiit")} ({statuses?.status_counts?.state_5})</Link>
        </li>
      </ul>
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
        tableStickyHeader={true}
      />
      
      <Modal
        title={t("Maksutiedot")}
        isOpen={modalOrderPaymentOpen}
        toggleModal={toggleModalOrderPayment}
      >
        <PurchaseOrderPaymentEdit
          purchaseOrderId={modalOrderPaymentId}
          handleResolve={handleOrderPaymentResolve}
          handleReject={handleOrderPaymentReject}
        />
      </Modal>
      
      <Modal
        title={t("Kuljetustiedot")}
        isOpen={modalOrderShippingOpen}
        toggleModal={toggleModalOrderShipping}
      >
        <SupplierOrderShippingEdit
          purchaseOrderId={modalOrderShippingId}
          handleResolve={handleOrderShippingResolve}
          handleReject={handleOrderShippingReject}
        />
      </Modal>
      
    </>
  );
}

export default PurchaseOrders;
